import React, { useContext, useState, useEffect } from 'react';
import { ColorContext } from '../context/ColorContext';
import { GetColorName } from 'hex-color-to-color-name';
import close from '../assets/close.png';


const ProductColor = ({ colors, closePropertiesSidebar, selcolor,setColorName  }) => {
  const { setSelectedColor, setSelectedColorName, selectedColor, setSelectedSizes,updateColorIdInUrl } = useContext(ColorContext);
  const [selectedSize, setSelectedSize] = useState([]);
  const [hoveredColor, setHoveredColor] = useState(null); // State for previewing color on hover

  const handleColorClick = (colorObj) => {
    setSelectedColor(colorObj.hexCode); // Apply selected color in context
    setSelectedColorName(colorObj.colorName); // Apply selected color name in context
    console.log("ColorObj",colorObj);
    setColorName(colorObj.colorName);
    setSelectedSize(colorObj.sizes || []); // Update sizes for the selected color
    setSelectedSizes(colorObj.sizes || []); // Update sizes for the selected color in context
    selcolor(colorObj.hexCode);
    updateColorIdInUrl(colorObj.colorId);
  };

  const handleColorHover = (colorObj) => {
    setHoveredColor(colorObj); // Set color for preview on hover
  };

  const handleColorLeave = () => {
    setHoveredColor(null); // Clear preview on hover leave
  };

  

  useEffect(() => {
    const currentColor = colors.find(colorObj => colorObj.hexCode === selectedColor);
    if (currentColor) {
      setSelectedSizes(currentColor.sizes || []);
      setSelectedSize(currentColor.sizes || []); // Set sizes for the currently selected color
    }
  }, [selectedColor, colors, setSelectedSizes]);
  console.log("SelectedColored",selectedColor);

  const displayColor = hoveredColor ? hoveredColor.hexCode : selectedColor;
  const displaySizes = hoveredColor ? hoveredColor.sizes : selectedSize;

  return (
    <div
    
    style={{

      marginBottom:"20px",
    }}>
          <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3px",
          backgroundColor: "#f6f6f6",
        }}
      >
        <h5
          style={{
            margin: "0 auto",
            color: "#211e1eaa",
            fontWeight: "400",
            textAlign: "center",
            flexGrow: 1,
          }}
        >
          Choose file 
        </h5>
        
        <button
          onClick={closePropertiesSidebar}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            fontSize: "18px",
            fontWeight: "bold",
            marginLeft: "auto",
            color: "#aaaa",
            fontWeight: "300",
            float: "right",

          }}
          aria-label="Close"
        >
          <img src={close} alt="Close" className="closeicon"/>
        </button>
      </div>

      <div className="color-palette">
        {colors.map((colorObj) => (
          <button
            key={colorObj.colorName}
            style={{
              backgroundColor: colorObj.hexCode,
              padding: '10px',
              margin: '5px',
              border: selectedColor === colorObj.hexCode ? '2px solid black' : 'none',
              width: "25px",
              height: "25px",
              cursor: 'pointer',
              boxSizing: 'border-box',
              borderRadius: '4px',
            }}
            onMouseEnter={() => handleColorHover(colorObj)} // Preview on hover
            onMouseLeave={handleColorLeave} // Clear preview when leaving
            onClick={() => handleColorClick(colorObj)} // Apply on click
          />
        ))}
      </div>

      {displayColor && (
        <div className='size-productcolor' style={{ paddingLeft: "12px" }}>
<h4>Sizes Available in: {colors.find(color => color.hexCode === displayColor)?.colorName}</h4>
<p>{displaySizes && displaySizes.length > 0 ? displaySizes.join(', ') : 'No sizes available'}</p>
        </div>
      )}
    </div>
  );
};

export default ProductColor;
