import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import CustomBoundingBox from './CustomBoundingBox';
import ResizeLogicComponent from './ResizeLogicComponent';
import DuplicateLogicComponent from './DuplicateLogicComponent';
import ProductImageComponentTwo from './ProductImageComponentTwo';
import WorkingAreaComponent from './WorkingAreaComponent';
import DesignAreaComponentTwo from './DesignAreaComponentTwo';
import cursorGrab from '../assets/cursors/cursor-grab.svg';
import cursorGrabbed from '../assets/cursors/cursor-grabbed.svg';
import HistoryDisplay from './HistoryDisplay';
import "../style.css"
// Load images
import Path from './path-warpnew';

import undoHoverImage from '../assets/undohover.png';
import undoInactiveImage from '../assets/undoinactive.png';
import undoActiveImage from '../assets/undoactive.png';
import redoHoverImage from '../assets/redohover.png';
import redoInactiveImage from '../assets/redoinactive.png';
import redoActiveImage from '../assets/redoactive.png';
import priceTableData from "../json/complete_price_table.json";
import EmbrioderyPrice from "../json/priceEmbroidery.json";


fabric.Canvas.prototype.getObjectById = function (id) {
  return this.getObjects().find(obj => obj.id === id);
};

const extractColorsFromObject = (obj, includeStroke = true) => {
  const colors = new Set();

  const hexToRgb = (hex) => {
    // Ensure hex is in correct format (#RRGGBB)
    if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) return hex;
    
    let c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    const r = parseInt(c[0] + c[1], 16);
    const g = parseInt(c[2] + c[3], 16);
    const b = parseInt(c[4] + c[5], 16);
    
    return `rgb(${r}, ${g}, ${b})`;
  };

  const addColor = (color) => {
    if (!color) return;
    const normalizedColor = color.startsWith("#") ? hexToRgb(color) : color;
    colors.add(normalizedColor);
  };

  if (obj.type === 'group') {
    obj.getObjects().forEach((childObj) => {
      addColor(childObj.fill);
      if (includeStroke) addColor(childObj.stroke);
    });
  } else if (obj.type === 'image') {
    if (obj.singleColorMode) {
      addColor(`rgb(${obj.singleColor.r}, ${obj.singleColor.g}, ${obj.singleColor.b})`);
    } else if (obj.makeColorMode) {
      addColor(`rgb(${obj.makeColor.r}, ${obj.makeColor.g}, ${obj.makeColor.b})`);
    } else if (obj.paletteColors) {
      obj.paletteColors.forEach((color) => {
        addColor(`rgb(${color[2]}, ${color[1]}, ${color[0]})`);
      });

      // If bgRemove is active, remove one color from the palette
      if (obj.bgRemove && obj.paletteColors.length > 0) {
        const dominantColor = `rgb(${obj.paletteColors[0][2]}, ${obj.paletteColors[0][1]}, ${obj.paletteColors[0][0]})`;
        colors.delete(dominantColor);
      }
    }
  } else {
    addColor(obj.fill);
  }

  if (includeStroke) {
    addColor(obj.stroke);
  }

  console.log('Extracted Colors:', Array.from(colors));
  console.log('Total Colors:', colors.size);

  return Array.from(colors);
};

const CanvasComponentTwo = ({setGetValue, switchBar, setCanvasRef, updateSelectedObjectSize, onObjectSelected, canvasKey, addToHistory, history, setHistory, saveCanvasDataToLocalStorage, updateCanvasPrice, isPriceSidebarVisible, Canvasid, pid, quantity }) => {
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [controlSettings, setControlSettings] = useState({
    enableCorners: true,
    cornerSize: 30,
    cornerColor: 'blue',
    cornerStrokeColor: 'red',
    borderColor: 'rgb(8, 149, 169)',
    borderOpacityWhenMoving: 0.8,
    borderScaleFactor: 2,
    iconSize: 30,
    iconOpacity: 1.0,
  });
  const [designArea, setDesignArea] = useState(null);
  const [activePart, setActivePart] = useState('Back');
  const [selectedPart, setSelectedPart] = useState(null);
  const [workingArea, setWorkingArea] = useState(null);
  const [initialState, setInitialState] = useState(null);
  
  const [isSwitchingTab, setIsSwitchingTab] = useState(false);

  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }


  const generateUniqueId = () => `_${Math.random().toString(36).substr(2, 9)}`;

  useEffect(() => {
    const canvasElement = canvasRef.current;
    const newCanvas = new fabric.Canvas(canvasElement, {
      selection: true,
      selectionColor: 'rgba(255, 255, 255, 0.3)',
      selectionBorderColor: 'white',
      selectionLineWidth: 0.7,
    });

    newCanvas.defaultCursor = 'default';
    newCanvas.hoverCursor = `url(${cursorGrab}), auto`;
    newCanvas.moveCursor = `url(${cursorGrabbed}), auto`;
    setCanvas(newCanvas);
    setCanvasRef(newCanvas);
    window._canvas = newCanvas;

    const updateCanvasSize = () => {
      const canvasContainer = canvasElement.parentNode;
      const containerWidth = canvasContainer.clientWidth;
      const containerHeight = canvasContainer.clientHeight;

      newCanvas.setWidth(containerWidth);
      newCanvas.setHeight(containerHeight);
      newCanvas.renderAll();
      console.log('Updated canvas size:', containerWidth, containerHeight);
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);

    return () => {
      window.removeEventListener('resize', updateCanvasSize);
      newCanvas.dispose();
    };
  }, [setCanvasRef]);

  useEffect(() => {
    if (canvas) {
      const onObjectModified = (e) => {
        if (!isSwitchingTab && e.transform) {
          const obj = e.target;
          const prevState = { ...obj.originalState };
          const newState = obj.toObject(['left','filters',  'color',
            'mode',
            'type',
            'alpha',
            'bgremove',
            'bringToFront',
            'center',
            'singleColor',
            'MakeColor',
            'makeOneColor',
            "dominant_color",
            'singleColorMode',
            'makeColorMode',  'top', 'scaleX', 'scaleY','letterSpacing','originalText',           // Save singleColorMode (true/false)
            'src', 'fill', 'topPercent','imageFile','bgRemove','rotation', 'leftPercent', 'paletteColors', 'session_id','originalPath','bend','warpType','distortH',
            'distortV',]);
          updateObjectProportions(obj);
          constrainObjectToDesignArea(obj);
          addToHistory(obj, prevState, newState, 'modify');
          obj.originalState = newState;
          saveObjectToLocalStorage(obj, canvasKey);
          saveCanvasDataToLocalStorage();  // Save data after any object modification
  
          calculatePrice();
        }
      };
  
  
        const onObjectScaling = (e) => {
          constrainObjectScaling(e.target);
        };
  
        const onObjectMoving = (e) => {
          constrainObjectToDesignArea(e.target);
        };
  
        const handleObjectAdded = (e) => {
          const obj = e.target;
          if (obj.designElement) {
            obj.partName = canvasKey;
            obj.id = obj.id || generateUniqueId();
            obj.originalState = obj.toObject(['left','clipartPath',  'color',
              'mode',
              'type',
              'alpha',
              'rotation',
              'bgremove',
              'singleColor',
              'makeColor',
              'makeOneColor',
              'dominant_color',
              'singleColorMode',
              'makeColorMode', 'filters','imageFile','originalText','strokeColor','letterSpacing',         // Save singleColorMode (true/false)
  'top', 'scaleX', 'scaleY', 'src', 'fill', 'topPercent','bgRemove', 'leftPercent', 'paletteColors', 'session_id', 'originalPath','bend','warpType','distortH',
            'distortV','stroke','strokeWidth', 'bringToFront',
            'center',]);
            updateObjectProportions(obj);
                    constrainObjectToDesignArea(obj);
  
            addToHistory(obj, null, obj.originalState, 'add');
  
            saveObjectToLocalStorage(obj, canvasKey);
            calculatePrice(); // Call calculatePrice on object addition
          }
        };
  
        const handleObjectRemoved = (e) => {
          const obj = e.target;
          if (obj.designElement) {
            addToHistory(obj, obj.toObject(['left','clipartPath',  'color',
              'mode',
              'type',
              'alpha',
              'bgremove',
              'rotation',
              'singleColor',
              'imageFile',
              'makeColor',
              'makeOneColor',
              'singleColorMode',
              'makeColorMode', 'filters', 'top','originalText', 'scaleX','letterSpacing',          // Save singleColorMode (true/false)
              'scaleY', 'src', 'topPercent', 'leftPercent', 'paletteColors','bend','warpType', 'session_id','originalPath'
  
  ,
            'warpType', // Save warp type
            'bend', // Save bend value
            'distortH',
            'distortV',
            'originalPath',
               'fontPath',        // Save the font path
            'fontFamily',      // Save the font family
            'originalText',
            'stroke',
            'strokeWidth' ,
            'strokeColor',
            'bgRemove',
            'bringToFront',
            'center',
               // S
  
              ]), null, 'remove');
            removeObjectFromLocalStorage(obj.id, canvasKey);
            calculatePrice(); // Call calculatePrice on object removal
          }
        };
  
        canvas.on('object:modified', onObjectModified);
        canvas.on('object:scaling', onObjectScaling);
        canvas.on('object:moving', onObjectMoving);
        canvas.on('object:added', handleObjectAdded);
        canvas.on('object:removed', handleObjectRemoved);
  
        return () => {
          canvas.off('object:modified', onObjectModified);
          canvas.off('object:scaling', onObjectScaling);
          canvas.off('object:moving', onObjectMoving);
          canvas.off('object:added', handleObjectAdded);
          canvas.off('object:removed', handleObjectRemoved);
        };
      }
    }, [canvas, designArea, productImage, canvasKey, isSwitchingTab,  initialState]);

    useEffect(() => {
      const handleResize = debounce(() => {
        if (canvas && designArea) {
          const { left: designAreaLeft, top: designAreaTop, width: designAreaWidth, height: designAreaHeight } = designArea;
    
          canvas.getObjects().forEach((obj) => {
            if (obj.designElement) {
              const objWidthPercent = obj.widthPercent;
              const objHeightPercent = obj.heightPercent;
              const objLeftPercent = obj.leftPercent;
              const objTopPercent = obj.topPercent;
    
              const newWidth = (designAreaWidth * objWidthPercent) / 100;
              const newHeight = (designAreaHeight * objHeightPercent) / 100;
              const newLeft = designAreaLeft + (designAreaWidth * objLeftPercent) / 100;
              const newTop = designAreaTop + (designAreaHeight * objTopPercent) / 100;
    
              obj.set({
                left: newLeft,
                top: newTop,
                scaleX: newWidth / obj.width,
                scaleY: newHeight / obj.height,
              });
              obj.setCoords();
            }
          });
          canvas.renderAll();
        }
      }, 100);  // Adding a 100ms debounce to prevent excessive triggering
    
      window.addEventListener('resize', handleResize);
    
      return () => window.removeEventListener('resize', handleResize);
    }, [canvas, designArea]);

  const updateObjectProportions = (obj) => {
    if (obj.designElement && designArea) {
      const { left: designAreaLeft, top: designAreaTop, width: designAreaWidth, height: designAreaHeight } = designArea;

      const objLeftPercent = ((obj.left - designAreaLeft) / designAreaWidth) * 100;
      const objTopPercent = ((obj.top - designAreaTop) / designAreaHeight) * 100;
      const objWidthPercent = (obj.getScaledWidth() / designAreaWidth) * 100;
      const objHeightPercent = (obj.getScaledHeight() / designAreaHeight) * 100;

      obj.set({
        leftPercent: objLeftPercent,
        topPercent: objTopPercent,
        widthPercent: objWidthPercent,
        heightPercent: objHeightPercent,
        lockScalingFlip: true,
      });
    }
  };

  const constrainObjectToDesignArea = (obj) => {
    if (designArea) {
      // Get the rotated bounding box
      const { left, top, width, height } = obj.getBoundingRect(true); 
  
      // Design area dimensions
      const { left: daLeft, top: daTop, width: daWidth, height: daHeight } = designArea;
  
      // Calculate constraints based on the rotated bounding box
      if (left < daLeft) {
        obj.left += daLeft - left; // Shift right to align left edge
      }
      if (top < daTop) {
        obj.top += daTop - top; // Shift down to align top edge
      }
      if (left + width > daLeft + daWidth) {
        obj.left -= (left + width) - (daLeft + daWidth); // Shift left to fit within right edge
      }
      if (top + height > daTop + daHeight) {
        obj.top -= (top + height) - (daTop + daHeight); // Shift up to fit within bottom edge
      }
      obj.setCoords();
      canvas.renderAll();
    }
  };

  const constrainObjectScaling = (obj) => {
    if (designArea) {
      const { left: designAreaLeft, top: designAreaTop, width: designAreaWidth, height: designAreaHeight } = designArea;

      const scaledWidth = obj.scaleX * obj.width;
      const scaledHeight = obj.scaleY * obj.height;

      const maxScaleX = (designAreaLeft + designAreaWidth - obj.left) / obj.width;
      const maxScaleY = (designAreaTop + designAreaHeight - obj.top) / obj.height;

      if (!obj.previousScaleX || !obj.previousScaleY) {
        obj.previousScaleX = obj.scaleX;
        obj.previousScaleY = obj.scaleY;
      }

      const maxScale = Math.min(maxScaleX, maxScaleY);

      if (obj.left < designAreaLeft) {
        obj.left = designAreaLeft;
        obj.scaleX = obj.previousScaleX;
        obj.scaleY = obj.previousScaleY;
      } else if (obj.left + scaledWidth > designAreaLeft + designAreaWidth) {
        obj.scaleX = maxScale;
        obj.scaleY = maxScale;
      }

      if (obj.top < designAreaTop) {
        obj.top = designAreaTop;
        obj.scaleX = obj.previousScaleX;
        obj.scaleY = obj.previousScaleY;
      } else if (obj.top + scaledHeight > designAreaTop + designAreaHeight) {
        obj.scaleX = maxScale;
        obj.scaleY = maxScale;
      }

      if (obj.left >= designAreaLeft && obj.left + scaledWidth <= designAreaLeft + designAreaWidth) {
        obj.previousScaleX = obj.scaleX;
      }
      if (obj.top >= designAreaTop && obj.top + scaledHeight <= designAreaTop + designAreaHeight) {
        obj.previousScaleY = obj.scaleY;
      }

      obj.setCoords();
      canvas.renderAll();
    }
  };

  const captureObjectState = (obj) => {
    const state = obj.toObject(['left', 'top', 'scaleX', 'scaleY', 'angle', 'fill', 'topPercent', 'leftPercent','bend','warpType','stroke', 'strokeWidth']);
    if (obj.type === 'group') {
      state.objects = obj.getObjects().map(child => child.toObject(['left', 'top', 'scaleX', 'scaleY', 'angle', 'fill', 'topPercent', 'leftPercent','stroke', 'strokeWidth']));
    }
    return state;
  };
  const reformatSvgPath = (svgPath) => {
    const pathCommands = svgPath.match(/[MmLlHhVvCcSsQqTtAaZz]|-?\d*\.?\d+/g);
    let reformattedPath = '';

    for (let i = 0; i < pathCommands.length; i++) {
      const command = pathCommands[i];
      if (/[MmLlHhVvCcSsQqTtAaZz]/.test(command)) {
        reformattedPath += `\n${command}`;
      } else {
        reformattedPath += ` ${command}`;
      }
    }

    return reformattedPath;
  };
const applyObjectState = (obj, state) => {
  // Apply general properties
  obj.set({
    left: state.left,
    top: state.top,
    scaleX: state.scaleX,
    scaleY: state.scaleY,
    angle: state.angle,
    fill: state.fill,
    opacity: state.opacity,
    flipX: state.flipX,
    flipY: state.flipY,
          path: state.path,
      originalPath: state.originalPath,
      warpType: state.warpType,
      bend: state.bend,
      distortH: state.distortH,
      distortV: state.distortV,
       stroke: state.stroke, // Ensure stroke color is applied
    strokeWidth: state.strokeWidth, // Ensure stroke width is applied
    skewX: state.skewX,
    bringToFront:state.bringToFront,
    center:state.center,
    skewY: state.skewY,
     stroke: state.stroke, // Ensure stroke color is applied
    strokeWidth: state.strokeWidth, // Ensure stroke width is applied
    selectable: state.selectable !== undefined ? state.selectable : true,
    evented: state.evented !== undefined ? state.evented : true,
  });

  console.log("Object :",obj);

  // Apply image-specific properties
  if (obj.type === 'image' && state.src) {
    // Reload the image source if it has changed
    fabric.Image.fromURL(state.src, (img) => {
      obj.setElement(img.getElement());
      obj.setCoords();
      obj.set({
        paletteColors: state.paletteColors || [],
      });
      obj.canvas.renderAll();
            calculatePrice();

    });
  }  else {
    // Apply path-based properties
    obj.set({
      path: state.path,
      originalPath: state.originalPath,
      warpType: state.warpType,
      bend: state.bend,
      distortH: state.distortH,
      distortV: state.distortV,
    });


    if (state.path) {
      obj.path = new fabric.Path(state.path).path;

        obj.setCoords();
    }

    if (obj.type === 'group' && state.objects) {
      obj.getObjects().forEach((child, index) => {
        child.set(state.objects[index]);
        child.setCoords();
      });
    }
    obj.setCoords();
    obj.canvas.renderAll();
  }
};



  const deselectAllObjects = () => {
    if (canvas) {
      canvas.discardActiveObject();
      canvas.getActiveObjects().forEach((obj) => obj.set({ active: false }));
      canvas.renderAll();
    }
  };


  const saveObjectToLocalStorage = (obj, partName) => {
    if (!obj || !partName) return;

    let existingCanvasData = [];
    const existingDataJson = localStorage.getItem(partName);
    if (existingDataJson) {
      try {
        existingCanvasData = JSON.parse(existingDataJson);
      } catch (error) {
        console.error(`Failed to parse existing data for ${partName}:`, error);
      }
    }

    const objIndex = existingCanvasData.findIndex(existingObj => existingObj.id === obj.id);

    const newObjectData = {
      ...obj.toObject([
        'id', 'topPercent', 'leftPercent', 'widthPercent', 'heightPercent', 'pathOffset', 'scaleX', 'scaleY', 'zoomY', 'zoomX',
        'type', 'left', 'top', 'width', 'height', 'designElement', 'ownMatrixCache', 'aCoords', 'path', 'fromWorkingArea',
        'scaleX', 'scaleY', 'zIndex', 'angle', 'Fill', 'stroke', 'element', 'selectable', 'cacheTranslationX', 'cacheTranslationY',
        'designElement', 'oCoords', 'selectable', 'strokeWidth', 'text', 'fontSize', 'fontWeight', 'fontFamily', 'textAlign',
        'fontStyle', 'lineHeight', 'letterSpacing', 'underline', 'overline', 'linethrough', 'clipPath', 'opacity', 'isAddedText',
        'designElement', 'isAddedClipart','session_id', 'src',     'newImagePath', // Ensure newImagePath is saved
            'imageFile', // Save the image file flag
            'imageType', // Save the image type
            'paletteColors','fill','originalPath' ,'bgRemove','clipartPath','singleColorMode','singleColor','makeColor','warpType','filters','fontFamily','bend','stroke','originalText','strokeWidth' ,'bringToFront',
            'center', // Save the palette colors
      ]),
      zIndex: objIndex !== -1 ? objIndex : existingCanvasData.length
    };

    if (objIndex !== -1) {
      existingCanvasData[objIndex] = newObjectData;
    } else {
      existingCanvasData.push(newObjectData);
    }

    try {
      localStorage.setItem(partName, JSON.stringify(existingCanvasData));
      console.log(`Updated local storage for ${partName}:`, existingCanvasData);
    } catch (error) {
      console.error(`Failed to save data for ${partName}:`, error);
    }
  };




  const removeObjectFromLocalStorage = (objId, partName) => {
    if (!objId || !partName) return;

    let existingCanvasData = [];
    const existingDataJson = localStorage.getItem(partName);
    if (existingDataJson) {
      try {
        existingCanvasData = JSON.parse(existingDataJson);
      } catch (error) {
        console.error(`Failed to parse existing data for ${partName}:`, error);
      }
    }

    const updatedCanvasData = existingCanvasData.filter(obj => obj.id !== objId);

    try {
      localStorage.setItem(partName, JSON.stringify(updatedCanvasData));
      console.log(`Removed object from local storage for ${partName}:`, objId);
    } catch (error) {
      console.error(`Failed to update data for ${partName}:`, error);
    }
  };



const undo = () => {
  if (history.undo.length > 0) {
    const newHistory = { ...history };
    const lastChange = newHistory.undo.pop();
    const { objId, prevState, action } = lastChange;
    const obj = canvas.getObjectById(objId);

    deselectAllObjects();

    if (obj && obj.type === 'image') {

      // Handling for image objects
      if (action === 'add') {
        obj.set({
          opacity: 0,
          selectable: false,
          evented: false,
        });
         
      } else if (action === 'remove') {
        applyObjectState(obj, prevState);
        obj.set({
          opacity: 1,
          selectable: true,
          evented: true,
        });

  } else if (action === 'modify' || action === 'colorChange' || action === 'strokeColorChange' || action === 'strokeWidthChange') {        applyObjectState(obj, prevState);
      }

    } else {
      // Handling for path and group objects
      if (action === 'add' && obj) {
        canvas.remove(obj);
      } else if (action === 'remove') {
        fabric.util.enlivenObjects([prevState], function (objects) {
          const newObj = objects[0];
          newObj.set({ id: objId, designElement: true });
          canvas.add(newObj);
          newObj.setCoords();
        });
      } else if ((action === 'modify' || action === 'colorChange'|| action === 'strokeColorChange' || action === 'strokeWidthChange') && obj) {
        applyObjectState(obj, prevState);
      }

    }

    canvas.renderAll();
    newHistory.redo.push(lastChange);
    setHistory(newHistory);

    calculatePrice();
    saveCanvasDataToLocalStorage(canvasKey);
  }
};

const redo = () => {
  if (history.redo.length > 0) {
    const newHistory = { ...history };
    const lastUndo = newHistory.redo.pop();
    const { objId, newState, action } = lastUndo;
    const obj = canvas.getObjectById(objId);

    deselectAllObjects();

    if (obj && obj.type === 'image') {
      // Handling for image objects
      if (action === 'add') {
        applyObjectState(obj, newState);
        obj.set({
          opacity: 1,
          selectable: true,
          evented: true,
        });
      } else if (action === 'remove') {
        obj.set({
          opacity: 0,
          selectable: false,
          evented: false,
        });
  } else if (action === 'modify' || action === 'colorChange' || action === 'strokeColorChange' || action === 'strokeWidthChange') {        applyObjectState(obj, newState);
      }
    } else {
      // Handling for path and group objects
      if (action === 'remove' && obj) {
        canvas.remove(obj);
      } else if (action === 'add') {
        fabric.util.enlivenObjects([newState], function (objects) {
          const newObj = objects[0];
          newObj.set({ id: objId, designElement: true });
          canvas.add(newObj);
          newObj.setCoords();
        });
      } else if ((action === 'modify' || action === 'colorChange'|| action === 'strokeColorChange' || action === 'strokeWidthChange') && obj) {
        applyObjectState(obj, newState);
      }
    }

    canvas.renderAll();
    newHistory.undo.push(lastUndo);
    setHistory(newHistory);
     calculatePrice();
    saveCanvasDataToLocalStorage(canvasKey);
  }
};


  useEffect(() => {
    if (canvas) {
      const handleSelectionCleared = (e) => {
        const activeObjects = e.deselected;
        if (activeObjects) {
          activeObjects.forEach((obj) => updateObjectProportions(obj));
        }
      };

      canvas.on('selection:cleared', handleSelectionCleared);

      return () => {
        canvas.off('selection:cleared', handleSelectionCleared);
      };
    }
  }, [canvas, designArea]);

  const calculatePrice = () => {
    let additionalPrice = 0;
  
    if (switchBar === 'screenPrinting') {
      let colorSet = new Set();
  
      if (canvas) {
        canvas.getObjects().forEach((obj) => {
          if (obj.designElement && obj.opacity !== 0) { // Check if opacity is not 0
            const colors = extractColorsFromObject(obj);
            colors.forEach((color) => colorSet.add(color));
          }
        });
      }
  
      const numberOfColors = colorSet.size;
      const quantityMatches = priceTableData.filter(item => item.Quantity === Number(quantity));
      console.log("Color Set:", numberOfColors);
      console.log("Quantity Matches:", quantityMatches);
  
      if (quantityMatches.length > 0) {
        const quantityData = quantityMatches[0];
        const firstColorPrice = quantityData["1st Color Price"];
        const additionalColorPrice = quantityData["2nd Color Price"];
        const fullColorPrice = quantityData["Full Color Price"];
  
        if (numberOfColors > 0) {
          if (numberOfColors > 4) {
            // Use Full Color Price if number of colors exceeds 5
            additionalPrice += fullColorPrice;
          } else {
            // Calculate price for 1st color and additional colors
            additionalPrice += firstColorPrice;
            if (numberOfColors > 1) {
              additionalPrice += (numberOfColors - 1) * additionalColorPrice;
            }
          }
        }
      } else {
        console.error("No match found for quantity in Screen Printing pricing:", quantity);
      }
    } else if (switchBar === 'embroidery') {
      let hasColors = false;
  
      if (canvas) {
        canvas.getObjects().forEach((obj) => {
          if (obj.designElement && obj.opacity !== 0) { // Check if opacity is not 0
            const colors = extractColorsFromObject(obj);
            if (colors.length > 0) {
              hasColors = true;
            }
          }
        });
      }
  
      if (hasColors) {
        const quantityMatch = EmbrioderyPrice.find(item => item.Quantity === Number(quantity));
  
        if (quantityMatch) {
          additionalPrice = quantityMatch.Price;
        } else {
          console.error("No match found for quantity in Embroidery pricing:", quantity);
        }
      } else {
        console.log("No colors available on canvas for embroidery.");
      }
    }
  
    console.log("Additional Price:", additionalPrice);
    updateCanvasPrice(additionalPrice);
  };
  
  useEffect(() => {
    calculatePrice();
  },[quantity]);
  

  useEffect(() => {
    if (canvas) {
      canvas.on('object:added', calculatePrice);
      canvas.on('object:removed', calculatePrice);
      canvas.on('object:modified', calculatePrice);

      return () => {
        canvas.off('object:added', calculatePrice);
        canvas.off('object:removed', calculatePrice);
        canvas.off('object:modified', calculatePrice);
      };
    }
  }, [canvas]);

  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
      e.preventDefault();
      undo();
    }
    if ((e.ctrlKey || e.metaKey) && e.key === 'y') {
      e.preventDefault();
      redo();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [history]);





 // UndoButton component
const UndoButton = ({ history, undo }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getUndoIcon = () => {
    if (history.undo.length > 0) {
      return isHovered ? undoHoverImage : undoActiveImage;
    } else {
      return undoInactiveImage;
    }
  };

  return (
    <button
      onClick={undo}
      className="undobtn"
      disabled={history.undo.length === 0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={getUndoIcon()} alt="Undo" width="28" height="28" />
    </button>
  );
};

// RedoButton component
const RedoButton = ({ history, redo }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getRedoIcon = () => {
    if (history.redo.length > 0) {
      return isHovered ? redoHoverImage : redoActiveImage;
    } else {
      return redoInactiveImage;
    }
  };

  return (
    <button
      onClick={redo}
      className="redobtn"
      disabled={history.redo.length === 0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={getRedoIcon()} alt="Redo" width="28" height="28" />
    </button>
  );
};

console.log("ProductImage :",productImage);
console.log("DesignArea :", designArea);
  return (
    <div className={`canvas-area ${isPriceSidebarVisible ? 'canvas-area-none' : ''}`}>
      <canvas ref={canvasRef} />
      {canvas && <CustomBoundingBox controlSettings={controlSettings} canvas={canvas} />}
      {canvas && <ResizeLogicComponent canvas={canvas} productImage={productImage} />}
      {canvas && <DuplicateLogicComponent canvas={canvas} />}
      {canvas && (
        <ProductImageComponentTwo
          canvas={canvas}
          setImage={setProductImage}
          selectedColor="#ffffff"
          setSelectedPart={setSelectedPart}
          setWorkingArea={setWorkingArea}
          activePart={activePart}
          setActivePart={setActivePart}
          Canvasid={Canvasid}
          pid={pid}
          switchBars={switchBar}
          setGetValue={setGetValue}
        />
      )}
      {canvas && productImage && (
        <WorkingAreaComponent
          canvas={canvas}
          image={productImage}
          workingArea={workingArea}
        />
      )}
      {canvas && productImage && (
        <DesignAreaComponentTwo
          canvas={canvas}
          controlSettings={controlSettings}
          updateSelectedObjectSize={updateSelectedObjectSize}
          onObjectSelected={onObjectSelected}
          productImage={productImage}
          setDesignArea={setDesignArea}
          selectedPart={selectedPart}
          switchBar={switchBar}
          isPriceSidebarVisible={isPriceSidebarVisible}

        />
      )}
   <div className={`undo-redo-buttons ${isPriceSidebarVisible ? 'undo-redo-buttons-visible' : ''}`}>
  <UndoButton history={history} undo={undo} />
  <RedoButton history={history} redo={redo} />
</div>

    </div>
  );
};

export default CanvasComponentTwo;