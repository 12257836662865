import React, { useEffect, useState, useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import Cookies from "js-cookie";
import { getFromLocalStorage } from "../utils/helperFunctions";
import "./Paymentconfirm.css";
import favicon from '../assets/images/favicon.png';
import logo from "../assets/images/logo.png";
import accountadmin from "../assets/images/accountadmin.webp";
import accounthistory from "../assets/images/accounthistory.webp";
import accountlogout from "../assets/images/accountlogout.webp";
import accountsetting from "../assets/images/accountsetting.webp";
import accounthistoryHover from "../assets/images/accounthistoryHover.webp";
import accountsettingHover from "../assets/images/accountsettingHover.webp";
import accountlogoutHover from "../assets/images/accountlogoutHover.webp";
import phone from "../assets/images/phone.png";
import line from "../assets/images/line.png";
import adeliaFont from "./adelia.ttf"; // Import the Montserrat font


import html2canvas from "html2canvas";

import web from "../assets/images/web.png";
import mail from "../assets/images/mail.png";
import { removeFromLocalStorage } from "../utils/helperFunctions";
import { Link, useLocation, useNavigate } from "react-router-dom";



const MyAccount = () => {
    const navigate = useNavigate();

    const email = Cookies.get("Email_guest");
    const email1 = getFromLocalStorage("Email");
    const [activeTab, setActiveTab] = useState("tab1");
    const [invoicePreview, setInvoicePreview] = useState(null);
    const [ordersData, setOrdersData] = useState([]);
    const [userData, setUserData] = useState({});
    const invoiceRef = useRef();
    const [additionalData, setAdditionalData] = useState([]);

    const [showLogoutPopup, setShowLogoutPopup] = useState(false); // State for logout popup
    const [hoveredTab, setHoveredTab] = useState(""); // Tracks the currently hovered tab


    useEffect(() => {
        if (!email && !email1) return;
    
        const fetchAccountData = (emailToFetch) => {
            fetch(`https://backend.inkox.com/api/user_login_email/${emailToFetch}`)
                .then((response) => response.json())
                .then((data) => setUserData(data[0]))
                .catch((error) => console.error("Error fetching account data:", error));
        };
    
        const fetchOrderData = (emailToFetch) => {
            fetch(`https://backend.inkox.com/api/place-orders_email/${emailToFetch}`)
                .then((response) => response.json())
                .then((data) => {
                    if (Array.isArray(data)) {
                        setOrdersData(data);
                    } else {
                        console.error("Unexpected orders data format:", data);
                        setOrdersData([]);
                    }
                })
                .catch((error) => console.error("Error fetching orders data:", error));
        };
    
        const fetchAdditionalData = () => {
            fetch(`https://backend.inkox.com/api/qc-portal`)
                .then((response) => response.json())
                .then((data) => {
                    setAdditionalData(data); // Update state with additional data
                })
                .catch((error) => console.error("Error fetching additional data:", error));
        };
    
        if (email) {
            fetchAccountData(email);
            fetchOrderData(email);
            fetchAdditionalData(); // Fetch additional data
        } else if (email1) {
            fetchAccountData(email1);
            fetchOrderData(email1);
            fetchAdditionalData(); // Fetch additional data
        }
    }, [email, email1]);
    
    const handleTabChange = (tab) => {
        setInvoicePreview(null);
        setActiveTab(tab);
    };

    const handleInvoicePreview = (order) => {
        setInvoicePreview(order);
    };

    const handleBackToOrders = () => {
        setInvoicePreview(null);
    };
    const handleDownload = async () => {
        const workOrderElement = document.querySelector(".maininvoice-container");

        if (!workOrderElement) {
            console.error("Element not found");
            return;
        }

        const pdf = new jsPDF({
            orientation: "portrait",
            unit: "px",
            format: "a4",
        });

        // Embed the adelia font
        const fontResponse = await fetch(adeliaFont);
        const fontBuffer = await fontResponse.arrayBuffer();
        pdf.addFileToVFS("adelia.ttf", fontBuffer);
        pdf.addFont("adelia.ttf", "adelia", "normal");
        pdf.setFont("adelia");

        // Generate the PDF using the html method
        await pdf.html(workOrderElement, {
            x: 10,
            y: 10,
            width: pdf.internal.pageSize.getWidth() - 20,
            windowWidth: workOrderElement.scrollWidth,
            callback: function () {
                pdf.save("examplepdf.pdf");
            },
        });
    };
    
    

    const calculateOrderTotal = (cartData) => {
        return cartData.reduce((total, item) => total + parseFloat(item.totalPrice), 0);
    };

    const formatQuantities = (quantities) => {
        return Object.entries(quantities)
            .map(([size, quantity]) => `${size}: ${quantity}`)
            .join(", ");
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleUpdate = () => {
        if (!userData.id) {
            alert("User ID is missing!");
            return;
        }

        const updatedData = {
            name: userData.name,
            email: userData.email,
            pwd: userData.password,
        };

        fetch(`https://backend.inkox.com/api/user_login/${userData.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedData),
        })
            .then((response) => {
                if (response.ok) {
                    alert("Account details updated successfully!");
                    return response.json();
                } else {
                    throw new Error("Failed to update account details");
                }
            })
            .then((data) => {
                console.log("Updated data:", data);
                window.location.reload();
            })
            .catch((error) => {
                console.error("Error updating account details:", error);
                alert("Error updating account details. Please try again.");
            });
    };

    const handleLogout = () => {
        removeFromLocalStorage("Id");
        removeFromLocalStorage("Email");
        removeFromLocalStorage("APP_TOKEN");
        localStorage.clear(); // Clear all localStorage
        setUserData(null); // Reset user data
        setShowLogoutPopup(false); // Close the popup
        navigate("/login");

    };

    
    

    const handleMobileSelectChange = (event) => {
        const selectedTab = event.target.value;
        if (selectedTab === "tab3") {
            setShowLogoutPopup(true); // Show the logout popup
        } else {
            setActiveTab(selectedTab);
        }
    };
    

 console.log("userData",userData[0]);
 

    return (
        <LayoutWrapper>
            <div className="myaccount-page">
                <div className=" myaccount-container">
                    <div className="myaccount-wrapper">
                

                        {/* Mobile Select Dropdown */}
                        <div className="mobile-select">
                            <select onChange={handleMobileSelectChange} value={activeTab}>

                                
                                <option value="tab1"> Order History</option>
                                <option value="tab2">Account Setting</option>
                                <option value="tab3">Logout</option>
                            </select>
                        </div>

                        <div className="property">
                            <div className="sidebar-account-page">
                            <div className="header-wrapper">
  <h1 className="welcomeback-tag"> <span className="img-welometag"><img src={accountadmin} width={"50px"} /></span> Welcome Back {userData.name || "User"}</h1>
                        </div>
                        <ul className="side-menu">
    <li
        className={activeTab === "tab1" ? "active" : ""}
        onClick={() => handleTabChange("tab1")}
    >
        <img
            src={
                activeTab === "tab1"
                    ? accounthistoryHover // Active image
                    : accounthistory // Default image
            }
            width={"20px"}
            className="menu-icon"
        />
        Order History
    </li>
    <li
        className={activeTab === "tab2" ? "active" : ""}
        onClick={() => handleTabChange("tab2")}
    >
        <img
            src={
                activeTab === "tab2"
                    ? accountsettingHover // Active image
                    : accountsetting // Default image
            }
            width={"20px"}
            className="menu-icon"
        />
        Account Setting
    </li>
    <li
        className={activeTab === "tab3" ? "active" : ""}
        onClick={() => setShowLogoutPopup(true)}
    >
        <img
            src={
                activeTab === "tab3"
                    ? accountlogoutHover // Active image
                    : accountlogout // Default image
            }
            width={"20px"}
            className="menu-icon"
        />
        Logout
    </li>
</ul>


                            </div>

                            <div className="content-account">
                                {activeTab === "tab1" && (
                                    <div>
                                        {!email && !email1 ? (
                                            <div className="no-email">
                                                <p>Please login to preview your placed order details.</p>
                                            </div>
                                        ) : invoicePreview ? (
                                            <div className="main-invoice">
                                                <div className="maininvoice-container" style={{fontFamily:'adelia'}}>
                                            <div className="invoice-container" ref={invoiceRef}>
                                              {/* Header Section */}
                                              <div className="invoice-header">
                                                <div className="logo-section">
                                                  <img src={logo} className="logo-invoice" alt="Logo" />
                                                  <p>All Printing Needs</p>
                                                </div>
                                                <div className="company-details">
                                                  {/* <p>150 Milner Ave. Unit #49,</p>
                                                  <p>Scarborough, ON, M1S 3R3 </p> */}
                                                  <p>+123-456-7890<img src={phone} width={'10px'}/></p>
                                                  <p>info@inkox.com<img src={mail} width={'10px'}/></p>
                                                  <p>www.inkox.com <img src={web} width={'10px'}/></p>
                                                </div>
                                              </div>
                                              {/* <div class="gradient-line"></div> */}
                                              <div className=""><img src={line} width={'100%'}/></div>

                                          
                                              {/* Invoice Title */}
                                              <h1 className="invoice-title" style={{fontFamily:'adelia'}}>INVOICE</h1>
                                          
                                              {/* Billing and Shipping Details */}
                                              <div className="billing-shipping-details">
                                                <div>
                                                  <h4>BILLING ADDRESS</h4>
                                      
                                                  <p>{invoicePreview.billing_address}</p>
                                                </div>
                                               
                                                <div className="invoice-sidecolumn">
                                                  <p><strong>INVOICE NO: {invoicePreview.id}</strong></p>
                                                  <p><span>DATE:</span>  <span> {new Date(invoicePreview.created_at).toDateString()}</span></p>
                                                  <p>
    ESTIMATED DELIVERY:{" "}
    {invoicePreview.cartData[0]?.Delivery_date
        ? Object.values(invoicePreview.cartData[0].Delivery_date).join(", ")
        : "N/A"}
</p>
                                                  
                                                </div>
                                              </div>
                                              <div>
                                                  <h4 style={{paddingBottom:"6px"}}><b>SHIPPING ADDRESS</b></h4>
                                                  <p>{invoicePreview.deliveryFirstName} {invoicePreview.deliveryLastName}</p>
                                                  <p>{invoicePreview.organization || "N/A"}</p>
                                                  <p>{invoicePreview.address}</p>
                                                  <p>{invoicePreview.city}, {invoicePreview.state}</p>
                                                  <p>{invoicePreview.zip}</p>
                                                </div>
                                          
                                              {/* Product Table */}
                                              <table className="invoice-table">
  <thead>
    <tr>
      <th>DESCRIPTION</th>
      <th>UNIT PRICE</th>
      <th>QTY</th>
      <th>TOTAL</th>
    </tr>
  </thead>
  <tbody>
    {/* Product Rows */}
    {invoicePreview.cartData.map((item, index) => (
      <tr key={index}>
        <td>{item.productName}</td>
        <td>${parseFloat(item.unitPrice).toFixed(2)}</td>
        <td>{formatQuantities(item.quantities)}</td>
        <td>${parseFloat(item.totalPrice).toFixed(2)}</td>
      </tr>
    ))}

    {/* Subtotal Row */}
    
    <tr className="summary-row firstsummeryrow">
      <td colSpan="1" style={{ textAlign: "left" }}>
        <strong>SUBTOTAL:</strong>
      </td>
      <td colSpan="1" style={{ textAlign: "left" }}>
        <strong></strong>
      </td>

        <td colSpan="1" style={{ textAlign: "left" }}>
        <strong></strong>
      </td>
      <td colSpan="">${calculateOrderTotal(invoicePreview.cartData).toFixed(2)}</td>
    </tr>

    {/* Discount Row */}
    <tr className="summary-row">
      <td colSpan="3" style={{ textAlign: "right" }}>
      DISCOUNT:
      </td>
      <td>$0.00</td>
    </tr>

    {/* HST Row */}
{/* Tax Rate Row */}
<tr className="summary-row">
  <td colSpan="3" style={{ textAlign: "right" }}>
    Delivery Charges
  </td>
  <td>
    ${invoicePreview.cartData[0]?.delivery_charges 
      ? parseFloat(invoicePreview.cartData[0].delivery_charges).toFixed(2) 
      : "N/A"}
  </td>
</tr>
<tr className="summary-row">
  <td colSpan="3" style={{ textAlign: "right" }}>
    Tax Rate:
  </td>
  <td>
    {invoicePreview.cartData[0]?.tax_rate 
      ? `${parseFloat(invoicePreview.cartData[0].tax_rate).toFixed(2)}%` 
      : "N/A"}
  </td>
</tr>

{/* Total Row */}
<tr className="summary-row total-row">
  <td colSpan="3" style={{ textAlign: "right" }}>
    <strong>TOTAL PAID:</strong>
  </td>
  <td>
    <strong>
    ${invoicePreview.payment_amount
      ? parseFloat(invoicePreview.payment_amount).toFixed(2) 
      : "N/A"}
      </strong>
  </td>
</tr>

  </tbody>
</table>

                                          
                                              {/* Footer Section */}
                                            
                                            </div>
                                            
                                            <div className="invoice-footer">
                                            <div className="disclaimer">This purchase order has been electronically generated and requires no signature.</div>

                                             
                                             <div className="footer-icon">
                                             <img src={favicon} width={"20px"}/>
                                             </div>
                                           </div>
                                            </div>

                                          
                                            <div className="button-container">
                                              <button onClick={handleBackToOrders} className="back-button">
                                                Back to Orders
                                              </button>
                                              <button onClick={handleDownload} className="download-button">
                                                Download / Save PDF
                                              </button>
                                            </div>
                                          </div>
                                          
                                          
                                          
                                        ) : Array.isArray(ordersData) && ordersData.length === 0 ? (
                                            <div className="no-orders">
                                                <p>You have not placed any orders yet.</p>
                                            </div>
                                        ) : (
                                            <div className="table-container">
                                                <h2>Order History</h2>
                                                <table className="styled-table">
                                                  <thead>
    <tr>
        <th>Sno</th>
        <th>Product</th>
        <th>Design</th>
        <th>Quantity</th>
        <th>Preview</th>
        <th>Delivery Date</th>
        <th>Work Order</th>
        <th>track no</th>
        <th>Designer Final</th>
        <th>status</th>

        <th>Actions</th>
    </tr>
</thead>
<tbody>
    {Array.isArray(ordersData) &&
        ordersData.map((order, index) =>
            order.cartData.map((item, itemIndex) => {
                const additionalInfo = additionalData.find(
                    (data) => data.orderId === order.id
                );

                return (
                    <tr key={`${index}-${itemIndex}`}>
                        <td>{index + 1}</td>
                        <td>{item.productName}</td>
                        <td>{item.designName}</td>
                        <td>{formatQuantities(item.quantities)}</td>
                        <td className="account-preview">
                            {item.imagePreviews.map((img, imgIndex) => (
                                <img
                                    key={imgIndex}
                                    src={img.preview}
                                    alt={`Preview ${imgIndex + 1}`}
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        marginRight: "5px",
                                    }}
                                />
                            ))}
                        </td>
                        <td>
    {item.Delivery_date
        ? Object.entries(item.Delivery_date).map(([key, value], i) => (
              <div key={i}>{value}</div>
          ))
        : "Not Available"}
</td>
                        <td>
                            {additionalInfo?.work_order ? (
                                <a
                                    href={`https://backend.inkox.com/storage/${additionalInfo.work_order}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View Work Order
                                </a>
                            ) : (
                                "Not Available"
                            )}
                        </td>
                        <td>
                            {order.track_no ? (
                                <span>{order.track_no}</span>
                            ) : (
                                "not available"
                            )}
                        </td>

                        <td>
                            {additionalInfo?.designer_final ? (
                              <td>
               
    {additionalInfo?.designer_final ? (
        JSON.parse(additionalInfo.designer_final).map((fileName, index) => (
            <a
                key={index}
                href={`https://backend.inkox.com/storage/designer_portal/designer_upload_main/${fileName}`}
                download
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "block", marginBottom: "5px" }}
            >
                Download File {index + 1}
            </a>
        ))
    ) : (
        "Not Available"
    )}


                          </td>
                          
                            ) : (
                                "Not Available"
                            )}
                        </td>
                     
                        <td className="status-account">{order.orderStatus || "N/A"}</td> {/* Updated to display the main orderStatus */}
                                             
                        <td>
                            <button
                                className="seeinvoices"
                                onClick={() => handleInvoicePreview(order)}
                            >
                                See Invoice
                            </button>
                        </td>
                    </tr>
                );
            })
        )}
</tbody>

                                                </table>
                                            </div>
                                        )}
                                    </div>
                                )}
                                   {activeTab === "tab2" && (
                                    <div className="account-setting">
                                        <h2>Account Setting</h2>
                                        <form className="account-form">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={userData.name || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={userData.email || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={userData.password || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <button type="button" className="update-btn" onClick={handleUpdate}>
                                                Update
                                            </button>
                                        </form>
                                    </div>
                                )}

                                {activeTab === "tab3" && (
                                    <div className="logout-tab">
                                        <h3>Click Logout to Exit</h3>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {showLogoutPopup && (
                    <div className="logout-popup">
                        <div className="popup-content">
                            <h3>Are you sure you want to logout?</h3>
                            <div className="popup-actions">
                                <button onClick={handleLogout} className="confirm-btn">
                                    Yes
                                </button>
                                <button
                                    onClick={() => setShowLogoutPopup(false)}
                                    className="cancel-btn"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </LayoutWrapper>
    );
};

export default MyAccount;
