import { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { handleIconMouseDown, handleIconMouseUp, deleteObject, duplicateObject, renderIcon } from './UtilitiesBoundingBox';

const useCanvasEventHandlers = (canvas, icons, controlSettings) => {
  const hoverRectRef = useRef(null);
  const pressedIconRef = useRef(null);

  useEffect(() => {
    if (icons.deleteIcon && icons.duplicateIcon && icons.resizeIcon) {
      hoverRectRef.current = new fabric.Rect({
        fill: 'transparent',
        stroke: 'rgba(150, 150, 150, 1)',
        strokeDashArray: [2, 1],
        strokeWidth: 1,
        selectable: false,
        evented: false,
        visible: false,
      });
      canvas.add(hoverRectRef.current);

      canvas.on('mouse:over', handleMouseOver);
      canvas.on('mouse:out', handleMouseOut);
      canvas.on('object:moving', handleObjectMove);
      canvas.on('object:scaling', handleObjectMove);
      canvas.on('object:modified', handleObjectModified);
      canvas.on('mouse:down', handleMouseDown);
      canvas.on('mouse:up', handleMouseUp);

      const handleKeyDown = (e) => {
        if (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA') {
          return;
        }

        const activeObject = canvas.getActiveObject();
                console.log("activeObject",activeObject);

        if (!activeObject) return;

        switch (e.key) {
          case 'Delete':
            if (activeObject.type === 'activeSelection') {
              activeObject.forEachObject((obj) => canvas.remove(obj));
              canvas.discardActiveObject();
            } else {
              canvas.remove(activeObject);
            }
            canvas.requestRenderAll();
            break;
          case 'c':
            if (e.ctrlKey || e.metaKey) {
              canvas.clipboard = activeObject.clone();
            }
            break;
          case 'x':
            if (e.ctrlKey || e.metaKey) {
              canvas.clipboard = activeObject.clone();
              if (activeObject.type === 'activeSelection') {
                activeObject.forEachObject((obj) => canvas.remove(obj));
                canvas.discardActiveObject();
              } else {
                canvas.remove(activeObject);
              }
              canvas.requestRenderAll();
            }
            break;
          case 'v':
            if ((e.ctrlKey || e.metaKey) && canvas.clipboard) {
              canvas.clipboard.clone((clonedObj) => {
                canvas.discardActiveObject();
                clonedObj.set({
                  left: clonedObj.left + 10,
                  top: clonedObj.top + 10,
                  evented: true,
                });
                if (clonedObj.type === 'activeSelection') {
                  clonedObj.canvas = canvas;
                  clonedObj.forEachObject((obj) => {
                    canvas.add(obj);
                  });
                  clonedObj.setCoords();
                } else {
                  canvas.add(clonedObj);
                }
                canvas.setActiveObject(clonedObj);
                canvas.requestRenderAll();
              });
            }
            break;
          default:
            break;
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        canvas.off('mouse:over', handleMouseOver);
        canvas.off('mouse:out', handleMouseOut);
        canvas.off('object:moving', handleObjectMove);
        canvas.off('object:scaling', handleObjectMove);
        canvas.off('object:modified', handleObjectModified);
        canvas.off('mouse:down', handleMouseDown);
        canvas.off('mouse:up', handleMouseUp);
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [icons, canvas]);

  const handleMouseOver = (e) => {
    if (hoverRectRef.current && e.target && !e.target.active) {
      const target = e.target;
      const bound = target.getBoundingRect();

      hoverRectRef.current.set({
        left: bound.left,
        top: bound.top,
        width: bound.width,
        height: bound.height,
        visible: true,
      });
      console.log("Mouse over: showing hover rect");
      canvas.renderAll();
    }
  };

  const handleMouseOut = () => {
    if (hoverRectRef.current) {
      hoverRectRef.current.set('visible', false);
      console.log("Mouse out: hiding hover rect");
      canvas.renderAll();
    }
  };

  const handleObjectMove = (e) => {
    if (hoverRectRef.current && e.target && !e.target.active) {
      const bound = e.target.getBoundingRect();
      hoverRectRef.current.set({
        left: bound.left,
        top: bound.top,
        width: bound.width,
        height: bound.height,
      });

      console.log("Moving: hiding controls");
      e.target.setControlsVisibility({
        deleteControl: false,
        duplicateControl: false,
        resizeControl: false,
      });

      canvas.renderAll();
    }
  };

  const handleObjectModified = (e) => {
    if (e.target) {
      const isImage = e.target.type === 'image' || e.target.originalState?.type === 'image';
  
      console.log("Modified: showing controls");
      e.target.setControlsVisibility({
        deleteControl: true,
        duplicateControl: !isImage, // Hide duplicate control for images
        resizeControl: true,
      });
  
      canvas.renderAll();
    }
  };
  
  const handleMouseDown = (e) => {
    if (!e.target) {
      pressedIconRef.current = null;
    }
  };
  
  const handleMouseUp = (e) => {
    if (e.target && !e.target.active) {
      const isImage = e.target.type === 'image' || e.target.originalState?.type === 'image';
  
      console.log("Mouse up: showing controls");
      e.target.setControlsVisibility({
        deleteControl: true,
        duplicateControl: !isImage, // Hide duplicate control for images
        resizeControl: true,
      });
  
      canvas.renderAll();
    }
    pressedIconRef.current = null;
  };
  
  return { hoverRectRef, pressedIconRef, handleMouseOver, handleMouseOut, handleMouseDown, handleMouseUp };
};

const applyCustomControls = (canvas, icons, controlSettings, hoverRectRef, pressedIconRef) => {
  fabric.Object.prototype.transparentCorners = !controlSettings.enableCorners;
  fabric.Object.prototype.cornerColor = controlSettings.cornerColor;
  fabric.Object.prototype.cornerStrokeColor = controlSettings.cornerStrokeColor;
  fabric.Object.prototype.cornerStyle = 'circle';
  fabric.Object.prototype.cornerSize = controlSettings.cornerSize;
  fabric.Object.prototype.borderColor = controlSettings.borderColor;
  fabric.Object.prototype.borderOpacityWhenMoving = controlSettings.borderOpacityWhenMoving;
  fabric.Object.prototype.borderScaleFactor = controlSettings.borderScaleFactor;

  const customControls = {
    deleteControl: new fabric.Control({
      x: -0.5,
      y: -0.5,
      offsetX: -controlSettings.iconSize / 2.7,
      offsetY: -controlSettings.iconSize / 2.7,
      cursorStyle: 'pointer',
      mouseDownHandler: handleIconMouseDown('delete', pressedIconRef),
      mouseUpHandler: handleIconMouseUp('delete', deleteObject, hoverRectRef, pressedIconRef),
      render: renderIcon(icons.deleteIcon, controlSettings.iconSize, controlSettings.iconOpacity),
      cornerSize: controlSettings.iconSize,
    }),
    resizeControl: new fabric.Control({
      x: 0.5,
      y: 0.5,
      offsetX: controlSettings.iconSize / 2.7,
      offsetY: controlSettings.iconSize / 2.7,
      cursorStyle: 'pointer',
      actionHandler: fabric.controlsUtils.scalingEqually,
      render: renderIcon(icons.resizeIcon, controlSettings.iconSize, controlSettings.iconOpacity),
      cornerSize: controlSettings.iconSize,
    }),
    duplicateControl: new fabric.Control({
      x: -0.5,
      y: 0.5,
      offsetX: -controlSettings.iconSize / 2.7,
      offsetY: controlSettings.iconSize / 2.7,
      cursorStyle: 'pointer',
      mouseDownHandler: handleIconMouseDown('duplicate', pressedIconRef),
      mouseUpHandler: handleIconMouseUp('duplicate', duplicateObject, hoverRectRef, pressedIconRef),
      render: renderIcon(icons.duplicateIcon, controlSettings.iconSize, controlSettings.iconOpacity),
      cornerSize: controlSettings.iconSize,
    }),
  };

  fabric.Object.prototype.controls = {
    ...fabric.Object.prototype.controls,
    ...customControls,
  };

  fabric.Textbox.prototype.controls = {
    ...fabric.Object.prototype.controls,
    ...customControls,
  };

  canvas.getObjects().forEach((obj) => {
    const isImage = obj.type === 'image' || obj.originalState?.type === 'image';
    
    obj.setControlsVisibility({
      mtr: false,
      tl: false,
      tr: false,
      bl: false,
      br: false,
      ml: false,
      mr: false,
      mb: false,
      mt: false,
      deleteControl: true,
      duplicateControl: !isImage, // Hide duplicate control for images
      resizeControl: true,
    });
  });

  const activeSelection = canvas.getActiveObject();
  if (activeSelection && activeSelection.type === 'activeSelection') {
    activeSelection.setControlsVisibility({
      mtr: false,
      tl: false,
      tr: false,
      bl: false,
      br: false,
      ml: false,
      mr: false,
      mb: false,
      mt: false,
      deleteControl: true,
      duplicateControl: true,
      resizeControl: true,
    });
  }
  
  canvas.renderAll();
};


export default useCanvasEventHandlers;
export { applyCustomControls };
