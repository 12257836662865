import React, { useState, useEffect } from 'react';
import './ProductColor.css'; // Import CSS file for styling
import FontCategories from './FontCategories';

const FontSelector = ({ text, onFontFamilyChange, onClose, handleCategoryChange, selectedCategory }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [fonts, setFonts] = useState([]);
  const [isFontSelectorOpen, setIsFontSelectorOpen] = useState(false);

  useEffect(() => {
    // Fetch the data from the provided API
    fetch("https://backend.inkox.com/api/featuredFont")
      .then((response) => response.json())
      .then((data) => {
        // Map the fetched data to the structure you need
        const allFonts = data.map(item => ({
          name: item.name,          // Name of the font
          fontPath: item.font_path, // Path to the font file
          category: item.category?.name || '', // Category of the font
          subCategory: item.sub_category || '' // Subcategory of the font (if exists)
        }));
        setFonts(allFonts); // Set the fonts array
      })
      .catch((error) => {
        console.error("Error fetching fonts:", error);
      });
  }, []);

  const handleFontFamilyChange = (fontPath, fontName) => {
    onFontFamilyChange(fontPath, fontName);
  };

  const loadFont = (fontPath, fontName) => {
    // Ensure the font path is HTTPS
    const secureFontPath = fontPath.startsWith("http:") ? fontPath.replace("http:", "https:") : fontPath;
    
    // Dynamically add each font to the document via @font-face
    const fontFace = new FontFace(fontName, `url(${secureFontPath})`);
    
    fontFace.load()
      .then(loadedFont => {
        document.fonts.add(loadedFont); // Add the font to the document
        console.log(`Font ${fontName} loaded successfully.`);
      })
      .catch(error => {
        console.error("Error loading font:", error);
      });
  };
  

  // Automatically load fonts for all fonts in the list
  useEffect(() => {
    fonts.forEach(font => {
      loadFont(font.fontPath, font.name);
    });
  }, [fonts]);

  // Filter fonts based on search term
  const filteredFonts = fonts.filter(font =>
    font.name && font.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleFontSelector = () => {
    setIsFontSelectorOpen(!isFontSelectorOpen);
  };

  return (
    <div className="font-selector">
      {isFontSelectorOpen ? (
        <>
          <FontCategories
            handleCategoryChange={handleCategoryChange}
            onClose={onClose}
            text={text}
            selectedCategory={selectedCategory}
            onFontFamilyChange={onFontFamilyChange}
          />
        </>
      ) : (
        <>
          <div className="search-container">
            <div className="search-box">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="#aaa" d="M23.7 22.3l-5.7-5.7c1.2-1.5 1.9-3.4 1.9-5.4 0-4.7-3.8-8.5-8.5-8.5S2.9 6.5 2.9 11.2 6.7 19.7 11.4 19.7c2 0 3.9-.7 5.4-1.9l5.7 5.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4zM11.4 17c-3.2 0-5.8-2.6-5.8-5.8s2.6-5.8 5.8-5.8 5.8 2.6 5.8 5.8-2.6 5.8-5.8 5.8z" />
              </svg>
              <input
                type="text"
                placeholder="Search Fonts"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="font-search"
              />
            </div>
          </div>

          <div className='inner' style={{ marginTop: "10px", paddingBottom: "10px !important" }}>
            <button onClick={toggleFontSelector} className='viewfontcat'>View All Categories</button>
          </div>

          <div className="font-grid">
            {filteredFonts.map(font => (
              <div
                key={font.fontPath}
                className="font-box"
               
                onClick={() => handleFontFamilyChange(font.fontPath, font.name)}
              >
                <div className="font-name"  style={{
                    fontFamily: font.name, // Apply each font's family
               
                  }}>{text}</div>
                {font.name}
              </div>
              
            ))}
          </div>
          <button onClick={onClose} className='button-to-text display-none-mobile donebutton' style={{ float: 'right' }}>Done</button>
        </>
      )}
    </div>
  );
};

export default FontSelector;
