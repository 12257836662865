import React, { useEffect, useState, useRef } from "react";
import { fabric } from "fabric";
import opentype from "opentype.js";
import Path from "./path-warp";
import "./ProductColor.css";
import FontSelector from "./FontSelector";
import ArcTypeSelector from "./ArcTypeSelector";
import ObjectColorComponent from "./ObjectColorComponent";
import close from "../assets/close.png"
import arrow from '../assets/blue-arrow-png-36989.png';
import bringtofront from '../assets/bringtofront.png';

import mirrorvertical from '../assets/vertical.png';

import mirrorhorizontal from '../assets/horizontal.png';
import lock from '../assets/lock.png';
import unlock from '../assets/unlock.png';
import objectcenter from '../assets/objectcenter.png';
import fontmobicon from '../assets/fontmobicon.svg';
import edittextmobicon from '../assets/edittextmobicon.svg';
import outlinefontmob from '../assets/outlinefontmob.svg';
import arcmobicon from '../assets/arcmobicon.svg';
import colormobicon from '../assets/colormobicon.svg';
import spacingmobicon from '../assets/spacingmobicon.svg';
import rotationmobtext from '../assets/rotationmobtext.svg';



const AddTextComponent = ({
  bend,
  setBend,
  horizontalDistort,
  setHorizontalDistort,
  verticalDistort,
  setVerticalDistort,
  warpType,
  setWarpType,
  applyTransformation,
  setOriginalPath,
  showApplyTransformationButton,
  isPropertiesView,
  selectedCategory,
  setSelectedCategory,
  selectedFont,
  setSelectedFont,
  textwidth,
  textheight,
  calculatePrice,
  addToHistory,
  getCanvasByPartName,
  activeTab,
  selectedObject,
  selectedObjectColors,
  setSelectedObjectColors,
  selectedColor,
  setSelectedColor,
  showColorPalette,
  setShowColorPalette,
  inkColors,
  setInkColors,
  onColorChange,
  handleFillChange,
  saveCanvasDataToLocalStorage,
  closePropertiesSidebar,
  onClose
}) => {
  const [textValue, setTextValue] = useState("");
  const [fonts, setFonts] = useState([]);
  const [availableFonts, setAvailableFonts] = useState([]);
  const [isTransforming, setIsTransforming] = useState(false);
  const transformationTimeoutRef = useRef(null);
  const [rotation, setRotation] = useState(0);
  const [isFontSelectorOpen, setIsFontSelectorOpen] = useState(false);
  const [isArcSelectorOpen, setIsArcSelectorOpen] = useState(false);
  const [letterSpacing, setLetterSpacing] = useState(0);
  const [selectedFontName, setSelectedFontName] = useState('');
  const [isLocked, setIsLocked] = useState();
 const [strokeColor, setStrokeColor] = useState('none');
 const [strokeWidth, setStrokeWidth] = useState(0);
 const [outlineColors, setOutlineColors] = useState([]);
 const [showOutlineSettings, setShowOutlineSettings] = useState(false); 
 const [hoveredOutlineColor, setHoveredOutlineColor] = useState(""); // State for hovered outline color name
const [selectedOutlineColorName, setSelectedOutlineColorName] = useState(""); // State for selected outline color name

 const [activeTabIndex, setActiveTabIndex] = useState(null); // Manage active tab for mobile screens
 const isMobileScreen = window.innerWidth <= 900;

 const [isSelected, setIsSelected] = useState(false);
 const [isFlippedHorizontal, setIsFlippedHorizontal] = useState(false); // State for flip horizontal
 const [isFlippedVertical, setIsFlippedVertical] = useState(false); // St
 const [isCentered, setIsCentered] = useState(false); // State to handle selected class for centering
 const [isColor, setIsColor] = useState(false);

 
 const reformatSvgPath = (svgPath) => {
  const pathCommands = svgPath.match(/[MmLlHhVvCcSsQqTtAaZz]|-?\d*\.?\d+/g);
  let reformattedPath = '';

  for (let i = 0; i < pathCommands.length; i++) {
      const command = pathCommands[i];
      if (/[MmLlHhVvCcSsQqTtAaZz]/.test(command)) {
          reformattedPath += `\n${command}`;
      } else {
          reformattedPath += ` ${command}`;
      }
  }

  return reformattedPath;
};


  const previousValuesRef = useRef({
    warpType: warpType,
    bend: bend,
    horizontalDistort: horizontalDistort,
    verticalDistort: verticalDistort,
  });

  // useEffect(() => {
  //   fetch("/fonts.json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setFonts(data.categories);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching fonts:", error);
  //     });
  // }, []);

  useEffect(() => {
    fetch('https://backend.inkox.com/api/inkcolors')
      .then((response) => response.json())
      .then((data) => {
        const allColors = data.map((item) => item.color_hex.split(',')).flat().map(color => color.trim());
      setOutlineColors(allColors);
      })
      .catch((error) => {
        console.error('Error fetching outline colors:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedObject && selectedObject.type === 'path' && selectedObject.designElement) {
    setTextValue(selectedObject.originalText || '');
    setStrokeColor(selectedObject.stroke || 'none');
    setStrokeWidth(selectedObject.strokeWidth !== undefined ? selectedObject.strokeWidth : 0);
    setBend(selectedObject.bend || 0);
    setHorizontalDistort(selectedObject.distortH || 0);
    setVerticalDistort(selectedObject.distortV || 0);
    setWarpType(selectedObject.warpType || 'None');
    setLetterSpacing(selectedObject.letterSpacing !== undefined ? selectedObject.letterSpacing : 0);
    setIsFlippedHorizontal(selectedObject.flipX || false);
    setIsFlippedVertical(selectedObject.flipY || false);
    setIsCentered(selectedObject.isCentered || false); 
    setSelectedFont(selectedObject.fontPath || 'https://inkox.com/fonts/Cheesecake.ttf');
    
    // Add this line to set the selected font name
    setSelectedFontName(selectedObject.fontFamily || 'Cheesecakes');  // You may need to adjust this if the font name is stored elsewhere.


    setIsSelected(selectedObject.isSelected || false); 
    setRotation(selectedObject.angle || 0);

    
    }
    }, [selectedObject]);


    
    
    const getOutlineColorName = (hex) => {
  // Add logic to fetch the name of the color based on the hex (similar to ObjectColorComponent)
  for (const item of outlineColors) {
    if (item.colorHex === hex) {
      return item.colorName || ""; // Assuming outlineColors is an array with colorHex and colorName
    }
  }
  return ""; // Default to empty if not found
};

const handleOutlineColorHover = (color) => {
  const colorName = getOutlineColorName(color);
  setHoveredOutlineColor(colorName);
};

const handleOutlineColorHoverOut = () => {
  setHoveredOutlineColor("");
};
const warpTypeDisplayNames = {
  WARP_ARC: "Arc",
  WARP_ARC_LOWER: "Arc Lower",
  WARP_ARC_UPPER: "Arc Upper",
  WARP_ARCH: "Arch",
  WARP_BULGE: "Bulge",
  WARP_FLAG: "Flag",
  WARP_FISH: "Fish",
  WARP_RISE: "Rise",
  WARP_INFLATE: "Inflate",
  WARP_SQUEEZE: "Squeeze",
  WARP_WAVE_LOWER: "Wave Lower",
  WARP_WAVE_UPPER: "Wave Upper",
};


useEffect(() => {
  // Set selected outline color name when strokeColor changes
  const colorName = getOutlineColorName(strokeColor);
  setSelectedOutlineColorName(colorName);
}, [strokeColor]);

    const handleRotationChange = (e) => {
      const newRotation = parseInt(e.target.value, 10);
      setRotation(newRotation);
    
      const canvas = window._canvas;
      const selectedObject = canvas ? canvas.getActiveObject() : null;
      if (selectedObject) {
        selectedObject.set({
          angle: newRotation,
          originX: 'center',  // Set rotation origin to center
          originY: 'center'
        });
        selectedObject.setCoords();
        canvas.renderAll();
      }
    };
    

  useEffect(() => {
    if (selectedCategory) {
      localStorage.setItem("selectedCategory", selectedCategory);
    }
    if (selectedFont) {
      localStorage.setItem("selectedFont", selectedFont);
    }
  }, [selectedCategory, selectedFont]);

  useEffect(() => {
    const savedCategory = localStorage.getItem("selectedCategory");
    const savedFont = localStorage.getItem("selectedFont");

    if (savedCategory) {
      setSelectedCategory(savedCategory);
    }
    if (savedFont) {
      setSelectedFont(savedFont);
    }
  }, []);

  useEffect(() => {
    if (selectedCategory && Array.isArray(fonts)) {
      const category = fonts.find(
        (category) => category.category === selectedCategory
      );
      if (category && category.fonts) {
        setAvailableFonts(category.fonts);
        const savedFont = localStorage.getItem("selectedFont");
        setSelectedFont(savedFont || category.fonts[0].fontPath);
      }
    }
  }, [selectedCategory, fonts]);

  useEffect(() => {
    if (textValue && selectedFont) {
      opentype.load(selectedFont, (err, font) => {
        if (err) {
          console.error("Font could not be loaded: ", err);
        } else {
          const fontSize = 72;
          const path = font.getPath(textValue, 0, 0, fontSize);
          const svgPath = path.toPathData(2);
          setOriginalPath((svgPath));
        }
      });
    }
  }, [textValue, selectedFont, setOriginalPath]);

  useEffect(() => {
    const canvas = window._canvas;
    if (canvas) {
      canvas.on("object:selected", () => {
        const selectedObject = canvas.getActiveObject();
        if (
          selectedObject &&
          selectedObject.type === "path" &&
          selectedObject.designElement
        ) {
          setLetterSpacing(selectedObject.charSpacing || 0);
          setWarpType(selectedObject.warpType || "");
          setBend(
            selectedObject.bend !== undefined ? selectedObject.bend : 100
          );
          setHorizontalDistort(
            selectedObject.distortH !== undefined ? selectedObject.distortH : 0
          );
          setVerticalDistort(
            selectedObject.distortV !== undefined ? selectedObject.distortV : 0
          );
          setRotation(selectedObject.angle || 0);
          setSelectedFont(selectedObject.fontPath || selectedFont);

          selectedObject.set({
            originX: "center",
            originY: "center",
          });
          selectedObject.setCoords();
        }
      });

      canvas.on("selection:cleared", () => {
        setLetterSpacing(0);
        setWarpType("");
        setBend(0);
        setHorizontalDistort(0);
        setVerticalDistort(0);
        setRotation(0);
      });
    }
  }, [
    setWarpType,
    setBend,
    setHorizontalDistort,
    setVerticalDistort,
    selectedFont,
  ]);
  const handleBendChange = (e) => {
    const newValue = e.target.value;
    setBend(newValue);
    updateSelectedObject({ bend: newValue });
  };

  const handleHorizontalDistortChange = (e) => {
    const newValue = e.target.value;
    setHorizontalDistort(newValue);
    updateSelectedObject({ distortH: newValue });
  };

  const handleVerticalDistortChange = (e) => {
    const newValue = e.target.value;
    setVerticalDistort(newValue);
    updateSelectedObject({ distortV: newValue });
  };
  const handleWarpTypeChange = (e) => {
    const newValue = e.target.value;
    setWarpType(newValue);
    updateSelectedObject({ warpType: newValue });
  };
  

  const handleFontChange = (newFontPath, newFontName) => {
    // Ensure the font path is HTTPS
    const secureFontPath = newFontPath.startsWith("http:") ? newFontPath.replace("http:", "https:") : newFontPath;
    
    setSelectedFont(secureFontPath);
    setSelectedFontName(newFontName);
  
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
  
    if (selectedObject && selectedObject.type === "path" && selectedObject.designElement) {
      const originalText = selectedObject.originalText || textValue;
      if (originalText && secureFontPath) {
        opentype.load(secureFontPath, (err, font) => {
          if (err) {
            console.error("Font could not be loaded: ", err);
          } else {
            const fontSize = 72;
  
            // Apply letter spacing
            const svgPath = getSpacedPathData(font, originalText, fontSize);
            const reformattedSvgPath = reformatSvgPath(svgPath);
  
            // Apply warp, bend, and distortions
            const warpParameters = {
              type: selectedObject.warpType,
              bend: selectedObject.bend / 100,
              distortV: selectedObject.distortV / 100,
              distortH: selectedObject.distortH / 100,
            };
  
            const warpedPath = new Path(reformattedSvgPath);
            warpedPath.warp(warpParameters);
  
            // Create a new fabric.Path object, retaining stroke and letter spacing
            const newTextPath = new fabric.Path(warpedPath.output(), {
              fill: selectedObject.fill,
              stroke: selectedObject.stroke, // Retain stroke (outline) color
              strokeWidth: selectedObject.strokeWidth, // Retain stroke width (outline thickness)
              designElement: true,
              angle: selectedObject.angle, // Preserve rotation angle
              selectable: true,
              originalPath: svgPath,
              originalText: originalText,
              fontPath: secureFontPath,
              fontFamily: newFontName,
              left: selectedObject.left,
              top: selectedObject.top,
              scaleX: selectedObject.scaleX,
              scaleY: selectedObject.scaleY,
              warpType: selectedObject.warpType,
              bend: selectedObject.bend,
              distortV: selectedObject.distortV,
              distortH: selectedObject.distortH,
              letterSpacing: selectedObject.letterSpacing, // Retain letter spacing
              paintFirst: 'stroke', // Ensure stroke is painted first
            });
  
            // Remove the old object and add the new one to the canvas
            canvas.remove(selectedObject);
            canvas.add(newTextPath);
            canvas.setActiveObject(newTextPath);
            canvas.renderAll();
  
            applyTransformationWithPause();
          }
        });
      }
    }
  };
  
  

  const handleCategoryChange = (e) => {
    setSelectedCategory(e);
  };

  const updateSelectedObject = (newProps) => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (
      selectedObject &&
      selectedObject.type === "path" &&
      selectedObject.designElement
    ) {
      selectedObject.set({
        ...newProps,
      });
      selectedObject.setCoords();
      canvas.renderAll();
    }
  };

  const typingDelayRef = useRef(null);
  const handleTextChange = (e) => {
  const newValue = e.target.value;
  setTextValue(newValue);
  
  // Clear the previous timeout if there was any
  if (typingDelayRef.current) {
  clearTimeout(typingDelayRef.current);
  }
  
  // Set a timeout to update the text after a delay (e.g., 500ms)
  typingDelayRef.current = setTimeout(() => {
  const canvas = window._canvas;
  const selectedObject = canvas ? canvas.getActiveObject() : null;
  
  if (selectedObject && selectedObject.type === 'path' && selectedObject.designElement) {
  opentype.load(selectedObject.fontPath, (err, font) => {
  if (err) {
    console.error('Font could not be loadedaa: ', err);
  } else {
    const fontSize = 72;
    const path = font.getPath(newValue, 0, 0, fontSize, { letterSpacing: selectedObject.letterSpacing });
    const svgPath = path.toPathData(2);
    const reformattedSvgPath = reformatSvgPath(svgPath);
    // Only proceed if svgPath is valid
    if (svgPath && svgPath.length > 0) {
      // Apply warp and distortions to the new path
      const warpParameters = {
        type: selectedObject.warpType,
        bend: selectedObject.bend / 100,
        distortV: selectedObject.distortV / 100,
        distortH: selectedObject.distortH / 100,
      };
  
      const warpedPath = new Path(reformattedSvgPath);
      warpedPath.warp(warpParameters);
  
      // Create a new fabric.Path object, but keep all existing properties
      const newTextPath = new fabric.Path(warpedPath.output(), {
        fill: selectedObject.fill, // retain fill color
        stroke: selectedObject.stroke, // retain stroke color
        strokeWidth: selectedObject.strokeWidth, // retain stroke width
        designElement: true,
        selectable: true,
        originalPath: svgPath, // update original path with new text
        originalText: newValue, // update text
        fontPath: selectedFont,
        fontFamily: selectedFontName,
        left: selectedObject.left, // retain position
        top: selectedObject.top, // retain position
        scaleX: selectedObject.scaleX, // retain scale
        scaleY: selectedObject.scaleY, // retain scale
        warpType: selectedObject.warpType, // retain warp type
        bend: selectedObject.bend, // retain bend value
        distortV: selectedObject.distortV, // retain vertical distortion
        distortH: selectedObject.distortH, // retain horizontal distortion
        paintFirst: 'stroke', // retain paint order
        letterSpacing:selectedObject.letterSpacing,
        angle: selectedObject.angle, // Preserve rotation angle

      });
  
      // Remove the old object and add the new one to the canvas
      canvas.remove(selectedObject);
      canvas.add(newTextPath);
      canvas.setActiveObject(newTextPath);
      canvas.renderAll();
  
      // Save history state after text change
      const prevState = selectedObject.toObject();
      const newState = newTextPath.toObject();
      saveCanvasDataToLocalStorage(activeTab);
    } else {
      console.error('SVG Path is invalid or empty');
    }
  }
  });
  }
  }, 100); // 500ms delay before updating the text
  };
// 500ms delay before updating the text
  const getSpacedPathData = (font, text, fontSize) => {
    let x = 0;
    const paths = [];
    const scale = (1 / font.unitsPerEm) * fontSize;
    const glyphs = font.stringToGlyphs(text);
  
    glyphs.forEach((glyph, i) => {
      if (glyph) {
        const glyphPath = glyph.getPath(x, 0, fontSize);
        paths.push(glyphPath.toPathData(2)); // 2 decimal precision
        x += glyph.advanceWidth * scale + letterSpacing * fontSize; // Fix for letter spacing
      }
    });
  
    return paths.join(" ");
  };
  

  
  

  //  const handleAddTextToCanvas = () => {
  //   const canvas = window._canvas;
  
  //   if (!canvas) {
  //     console.error('Canvas is not initialized');
  //     return;
  //   }
  
  //   if (!textValue) {
  //     console.error('Text value is empty');
  //     return;
  //   }

    
    
  
  //   // Reset font, stroke, and other style properties before adding new text
  //   const defaultFont = 'https://inkox.com/fonts/Cheesecake.ttf'; // Set your default font path here
  //   const defaultFontName = 'Default Font'; // Set the default font name here
  //   const defaultStrokeColor = ''; // Default stroke color (none)
  //   const defaultStrokeWidth = 0; // Default stroke width (no outline)
  
  //   setSelectedFont(defaultFont);
  //   setSelectedFontName(defaultFontName);
  //   setStrokeColor(defaultStrokeColor);
  //   setStrokeWidth(defaultStrokeWidth);
  //   setLetterSpacing(0); // Reset letter spacing to default
  
  //   opentype.load(defaultFont, (err, font) => {
  //     if (err) {
  //       console.error('Font could not be loaded: ', err);
  //     } else {
  //       const fontSize = 72;
  //       const path = font.getPath(textValue, 0, 0, fontSize);
  //       const svgPath = path.toPathData(2);
  //       const reformattedSvgPath = reformatSvgPath(svgPath);
  //       const warpParameters = {
  //         type: warpType,
  //         bend: bend / 100,
  //         distortV: verticalDistort / 100,
  //         distortH: horizontalDistort / 100
  //       };
  
  //       const warpedPath = new Path(reformattedSvgPath); // Ensure Path is defined in the global scope
  //       warpedPath.warp(warpParameters);
  //       const newPath = new fabric.Path(warpedPath.output(), {
  //         fill: '#000000',
  //         stroke: defaultStrokeColor, // Default stroke color
  //         strokeWidth: defaultStrokeWidth, // Default stroke width
  //         designElement: true,
  //         selectable: true,
  //         originalPath: svgPath,
  //         originalText: textValue,
  //         fontPath: defaultFont,
  //         paintFirst: 'stroke',
  //         letterSpacing: 0, // Reset letter spacing
  //       });
  
  //       const designArea = canvas.getObjects().find((obj) => obj.designArea);
  //       if (designArea) {
  //         let left, top, scaleFactor;
  
  //         const selectedObject = canvas.getActiveObject();
  //         if (selectedObject && selectedObject.type === 'path' && selectedObject.designElement) {
  //           left = selectedObject.left;
  //           top = selectedObject.top;
  //           scaleFactor = selectedObject.scaleX;
  
  //           canvas.remove(selectedObject);
  //         } else {
  //           const leftPercent = 0;
  //           const topPercent = 0;
  //           const heightPercent = 10;
  //           left = designArea.left + (designArea.width * leftPercent) / 100;
  //           top = designArea.top + (designArea.height * topPercent) / 100;
  //           const targetHeight = designArea.height * (heightPercent / 100);
  //           scaleFactor = targetHeight / newPath.height;
  //         }
  
  //         newPath.set({
  //           left,
  //           top,
  //           scaleX: scaleFactor,
  //           scaleY: scaleFactor,
  //           letterSpacing: 0,
  //         });
  //         newPath.setCoords();
  //         canvas.add(newPath);
  //         canvas.setActiveObject(newPath);
  //         canvas.renderAll();
  //         saveCanvasDataToLocalStorage(activeTab);
  //       } else {
  //         newPath.set({ left: 100, top: 100 });
  //         canvas.add(newPath);
  //         canvas.setActiveObject(newPath);
  //         canvas.renderAll();
  //       }
  //     }
  //   });
  // };
  
  const handleAddTextToCanvas = () => {
    const canvas = window._canvas;
  
    if (!canvas) {
      console.error('Canvas is not initialized');
      return;
    }
  
    if (!textValue) {
      console.error('Text value is empty');
      return;
    }
  
    // Fetch default font from API before adding text
    fetch("https://backend.inkox.com/api/featuredFont")
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          const firstFont = data[0]; // Get first font from API
          const secureFontPath = firstFont.font_path.replace("http:", "https:"); // Ensure HTTPS
          setSelectedFont(secureFontPath);
          setSelectedFontName(firstFont.name);
  
          // Now, use the fetched font to create text
          opentype.load(secureFontPath, (err, font) => {
            if (err) {
              console.error('Font could not be loaded:', err);
            } else {
              const fontSize = 72;
              const path = font.getPath(textValue, 0, 0, fontSize);
              const svgPath = path.toPathData(2);
              const reformattedSvgPath = reformatSvgPath(svgPath);
              const warpParameters = {
                type: warpType,
                bend: bend / 100,
                distortV: verticalDistort / 100,
                distortH: horizontalDistort / 100
              };
  
              const warpedPath = new Path(reformattedSvgPath); // Ensure Path is defined globally
              warpedPath.warp(warpParameters);
  
              const newTextPath = new fabric.Path(warpedPath.output(), {
                fill: '#000000',
                stroke: '', // Default stroke color
                strokeWidth: 0, // Default stroke width
                designElement: true,
                selectable: true,
                originalPath: svgPath,
                originalText: textValue,
                fontPath: secureFontPath,
                fontFamily: firstFont.name,
                paintFirst: 'stroke',
                letterSpacing: 0, // Default letter spacing
              });
  
              // Add text inside the design area
              const designArea = canvas.getObjects().find((obj) => obj.designArea);
              if (designArea) {
                let left, top, scaleFactor;
  
                const selectedObject = canvas.getActiveObject();
                if (selectedObject && selectedObject.type === 'path' && selectedObject.designElement) {
                  left = selectedObject.left;
                  top = selectedObject.top;
                  scaleFactor = selectedObject.scaleX;
  
                  canvas.remove(selectedObject);
                } else {
                  const leftPercent = 0;
                  const topPercent = 0;
                  const heightPercent = 10;
                  left = designArea.left + (designArea.width * leftPercent) / 100;
                  top = designArea.top + (designArea.height * topPercent) / 100;
                  const targetHeight = designArea.height * (heightPercent / 100);
                  scaleFactor = targetHeight / newTextPath.height;
                }
  
                newTextPath.set({
                  left,
                  top,
                  scaleX: scaleFactor,
                  scaleY: scaleFactor,
                  letterSpacing: 0,
                });
                newTextPath.setCoords();
                canvas.add(newTextPath);
                canvas.setActiveObject(newTextPath);
                canvas.renderAll();
              } else {
                newTextPath.set({ left: 100, top: 100 });
                canvas.add(newTextPath);
                canvas.setActiveObject(newTextPath);
                canvas.renderAll();
              }
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching featured font:", error);
      });
  };
  
  const toggleOutlineSettings = () => {
    setShowOutlineSettings(!showOutlineSettings);
  };

  const letterSpacingTimeoutRef = useRef(null); 
  const handleLetterSpacingChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setLetterSpacing(newValue);
    
    if (letterSpacingTimeoutRef.current) {
    clearTimeout(letterSpacingTimeoutRef.current);
    }
    
    letterSpacingTimeoutRef.current = setTimeout(() => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    
    if (selectedObject && selectedObject.type === 'path' && selectedObject.designElement) {
    opentype.load(selectedFont, (err, font) => {
    if (err) {
      console.error('Font could not be loaded: ', err);
    } else {
      const path = font.getPath(selectedObject.originalText || textValue, 0, 0, 72, { letterSpacing: newValue });
      const svgPath = path.toPathData(2);
      const reformattedSvgPath = reformatSvgPath(svgPath);
      const warpParameters = {
        type: selectedObject.warpType,
        bend: selectedObject.bend / 100,
        distortV: selectedObject.distortV / 100,
        distortH: selectedObject.distortH / 100,
      };
    
      const warpedPath = new Path(reformattedSvgPath);
      warpedPath.warp(warpParameters);
    
      const newTextPath = new fabric.Path(warpedPath.output(), {
        fill: selectedObject.fill,
        stroke: selectedObject.stroke,
        strokeWidth: selectedObject.strokeWidth,
        designElement: true,
        selectable: true,
        originalPath: svgPath,
        originalText: selectedObject.originalText || textValue,
        fontPath: selectedFont,
        fontFamily: selectedFontName,
        left: selectedObject.left,
        top: selectedObject.top,
        scaleX: selectedObject.scaleX,
        scaleY: selectedObject.scaleY,
        warpType: selectedObject.warpType,
        bend: selectedObject.bend,
        distortV: selectedObject.distortV,
        distortH: selectedObject.distortH,
        paintFirst: 'stroke',
        letterSpacing: newValue,  // Set the letter spacing here
        angle: selectedObject.angle, // Preserve rotation angle

      });
    
      canvas.remove(selectedObject);
      canvas.add(newTextPath);
      canvas.setActiveObject(newTextPath);
      canvas.renderAll();
    
      const prevState = selectedObject.toObject();
      const newState = newTextPath.toObject();
      saveCanvasDataToLocalStorage(activeTab);
    }
    });
    }
    }, 300); // Debounce delay of 300ms
    };
  
  
  
  
  



  const constrainAndResizeObjectToDesignArea = (obj) => {
    const designArea = window._designArea;
  
    if (designArea) {
      const {
        left: designAreaLeft,
        top: designAreaTop,
        width: designAreaWidth,
        height: designAreaHeight,
      } = designArea;
  
      let objectLeft = obj.left;
      let objectTop = obj.top;
      let objectRight = objectLeft + obj.getScaledWidth();
      let objectBottom = objectTop + obj.getScaledHeight();
  
      // Adjust object position if it goes beyond the design area on any side
      if (objectLeft < designAreaLeft) {
        obj.set('left', designAreaLeft);
      }
      if (objectTop < designAreaTop) {
        obj.set('top', designAreaTop);
      }
      if (objectRight > designAreaLeft + designAreaWidth) {
        obj.set('left', designAreaLeft + designAreaWidth - obj.getScaledWidth());
      }
      if (objectBottom > designAreaTop + designAreaHeight) {
        obj.set('top', designAreaTop + designAreaHeight - obj.getScaledHeight());
      }
  
      // Calculate the new position and dimensions after adjustments
      objectLeft = obj.left;
      objectTop = obj.top;
      objectRight = objectLeft + obj.getScaledWidth();
      objectBottom = objectTop + obj.getScaledHeight();
  
      // Scale down the object if it's too large to fit inside the design area
      const scaleX = (designAreaWidth - (objectLeft - designAreaLeft)) / obj.getScaledWidth();
      const scaleY = (designAreaHeight - (objectTop - designAreaTop)) / obj.getScaledHeight();
      const scaleFactor = Math.min(scaleX, scaleY, 1);
  
      if (scaleFactor < 1) {
        obj.scaleX *= scaleFactor;
        obj.scaleY *= scaleFactor;
        obj.setCoords(); // Ensure bounding box is updated after scaling
      }
  
      // Finally, ensure the object is positioned correctly inside the design area
      if (obj.left < designAreaLeft) {
        obj.set('left', designAreaLeft);
      }
      if (obj.top < designAreaTop) {
        obj.set('top', designAreaTop);
      }
      if (obj.left + obj.getScaledWidth() > designAreaLeft + designAreaWidth) {
        obj.set('left', designAreaLeft + designAreaWidth - obj.getScaledWidth());
      }
      if (obj.top + obj.getScaledHeight() > designAreaTop + designAreaHeight) {
        obj.set('top', designAreaTop + designAreaHeight - obj.getScaledHeight());
      }
  
      obj.setCoords(); // Ensure bounding box is updated after final position adjustments
    }
  };
  
  
  

  const applyTransformationWithPause = () => {
    const {
      warpType: prevWarpType,
      bend: prevBend,
      horizontalDistort: prevHorizontalDistort,
      verticalDistort: prevVerticalDistort,
    } = previousValuesRef.current;

    if (
      warpType === prevWarpType &&
      bend === prevBend &&
      horizontalDistort === prevHorizontalDistort &&
      verticalDistort === prevVerticalDistort
    ) {
      return;
    }

    previousValuesRef.current = {
      warpType,
      bend,
      horizontalDistort,
      verticalDistort,
    };

    if (!isTransforming) {
      setIsTransforming(true);
      applyTransformation();
      transformationTimeoutRef.current = setTimeout(() => {
        setIsTransforming(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (
      warpType ||
      bend !== 0 ||
      horizontalDistort !== 0 ||
      verticalDistort !== 0
    ) {
      applyTransformationWithPause();
    }
  }, [warpType, bend, horizontalDistort, verticalDistort]);

  useEffect(() => {
    return () => clearTimeout(transformationTimeoutRef.current);
  }, []);

  const toggleFontSelector = () => {
    setIsFontSelectorOpen(!isFontSelectorOpen);
  };

  const toggleArcSelector = () => {
    setIsArcSelectorOpen(!isArcSelectorOpen);
  };

  const handleArcTypeSelect = (type) => {
    setWarpType(type);
    updateSelectedObject({ warpType: type });
  };
  const handleCenterObject = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.center();
      selectedObject.setCoords();
      canvas.renderAll();
  
      // Save the center state in the object
      selectedObject.isCentered = true; // Manually store the centered state
    }
  
    // Toggle the selected state for center button
    setIsCentered(!isCentered);
  };
  
  const handleBringToFront = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.bringToFront();
      canvas.renderAll();
  
      // Save the bring-to-front state in the object
      selectedObject.isSelected = true; // Manually store the bring-to-front state
    }
  
    setIsSelected(!isSelected); // Toggle the state
  };
  

 const handleFlipHorizontal = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.toggle('flipX');
      canvas.renderAll();
    }

    // Toggle the selected state for flip horizontal
    setIsFlippedHorizontal(!isFlippedHorizontal); 
  };

  const handleFlipVertical = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.toggle('flipY');
      canvas.renderAll();
    }

    // Toggle the selected state for flip vertical
    setIsFlippedVertical(!isFlippedVertical); 
  };

  const handleToggleLock = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {

      const isLocked = selectedObject.lockMovementX && selectedObject.lockMovementY;
      setIsLocked(!isLocked);
      selectedObject.set({
        lockMovementX: !isLocked,
        lockMovementY: !isLocked,
        lockScalingX: !isLocked,
        lockScalingY: !isLocked,
        lockRotation: !isLocked,
        selectable: isLocked,
        evented: isLocked,
      });
      canvas.renderAll();
    }
  };

  console.log("Text Value :",textValue);

  const saveHistoryState = (obj, prevState, newState, action) => {
    addToHistory(obj, prevState, newState, action);
  };

  const handleStrokeColorChange = (e) => {
    console.log("Colrr :", e);
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;

    if (selectedObject) {
      const newColor = e === 'none' ? '' : e;
      const prevState = selectedObject.toObject();

      setStrokeColor(newColor);
      selectedObject.set('stroke', newColor);
      selectedObject.setCoords();
      canvas.renderAll();

      const newState = selectedObject.toObject();
      saveHistoryState(selectedObject, prevState, newState, 'strokeColorChange');
      calculatePrice(canvas);
      saveCanvasDataToLocalStorage(activeTab);
    }

    setIsColor(!isColor);
  };

  const handleStrokeWidthChange = (e) => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;

    if (selectedObject) {
      const newWidth = e.target.value;
      const prevState = selectedObject.toObject();

      setStrokeWidth(newWidth);
      selectedObject.set('strokeWidth', newWidth);
      selectedObject.setCoords();
      canvas.renderAll();

      const newState = selectedObject.toObject();
      saveHistoryState(selectedObject, prevState, newState, 'strokeWidthChange');
      saveCanvasDataToLocalStorage(activeTab);
    }
  };



  const renderArcControls = () => (
    <>
    <ArcTypeSelector
      selectedArcType={warpType}
      onSelectArcType={handleWarpTypeChange}
      bend={bend}
      handleBendChange={handleBendChange}
      horizontalDistort={horizontalDistort}
      handleHorizontalDistortChange={handleHorizontalDistortChange}
      verticalDistort={verticalDistort}
      handleVerticalDistortChange={handleVerticalDistortChange}
    />
         <button onClick={closePropertiesSidebar} className='donebutton' style={{float:'right'}}>Done</button>

    </>
    
  );

  const renderColorControls = () => (
    <ObjectColorComponent
      canvas={getCanvasByPartName(activeTab)}
      selectedObject={selectedObject}
      selectedObjectColors={selectedObjectColors}
      setSelectedObjectColors={setSelectedObjectColors}
      selectedColor={selectedColor}
      setSelectedColor={setSelectedColor}
      showColorPalette={showColorPalette}
      setShowColorPalette={setShowColorPalette}
      inkColors={inkColors}
      setInkColors={setInkColors}
      onColorChange={onColorChange}
      handleFillChange={handleFillChange}
      addToHistory={addToHistory}
      calculatePrice={() => calculatePrice(getCanvasByPartName(activeTab))}
    />
  );

  const renderOutlineControls = () => (

    <div>
    <div className="inner">     <label>Chose Outline Thikness:</label>

    </div>
    <div className="inner"> 
      <br></br>
      <input
        type="range" className="range-bar"
        min="0"
        max="10"
        step="1"
        value={strokeWidth}
        onChange={handleStrokeWidthChange}
      />
</div>
<div className="inner"> 
<label>Chose Outline color:</label>
</div>
<div className="inner"> 
      <div className="color-boxes">
        
        {outlineColors.map((color) => (
          <div
            key={color}
            className="color-box"
            style={{
              backgroundColor: color,
              width: "25px",
              height: "25px",
              margin: "5px",
              cursor: "pointer",
              borderRadius: "5px",
            }}
            onClick={() => handleStrokeColorChange(color)}
          />
        ))}
      </div>
      </div>
      <div>

      </div>
      <div className="inner">  <button
        className="none-box donebutton"
       
        onClick={() => handleStrokeColorChange("none")}
      >
        None
      </button>


      <button onClick={closePropertiesSidebar} className='donebutton' style={{float:'right'}}>Done</button>
 
      
      
      
      </div>
    </div>
  );

  const renderLetterSpacingControls = () => (
    <>
      <label>Letter Spacing</label>
      <input
        type="range" className="range-bar"
        min="0"
        max="1"
        step=".1"
        value={letterSpacing}
        onChange={handleLetterSpacingChange}
      />
    </>
  );

  const renderFontControls = () => (
    <>
    <FontSelector
      text={selectedObject.originalText}
      onFontFamilyChange={handleFontChange}
      onClose={toggleFontSelector}
      handleCategoryChange={handleCategoryChange}
      selectedCategory={selectedCategory}
    />
             <button onClick={closePropertiesSidebar} className='donebutton' style={{float:'right'}}>Done</button>

    </>
  );

  const renderRotationControls = () => (
    <>
      <label>Rotation</label>
      <input
        type="range" className="range-bar"
        min="0"
        max="360"
        value={rotation}
        onChange={handleRotationChange}
      />
    </>
  );
  const renderEditTextControls = () => (
    <div>
      <input
        type="text"
        id="text_value"
        value={textValue}
        onChange={handleTextChange}
        placeholder="Edit your text"
        style={{ width: "97%", padding: "10px" }}
      />
    </div>
  );

  // Now you can define the tabs array after the render functions are declared
  const tabs = [
    { 
      label: (
        <div className="tab-label">
          <img src={edittextmobicon} alt="Edit Text Icon" />
          <br />
          Edit Text
        </div>
      ), 
      content: renderEditTextControls 
    },
    { 
      label: (
        <div className="tab-label">
          <img src={arcmobicon} alt="Arc Icon" />
          <br />
          Arc
        </div>
      ), 
      content: renderArcControls 
    },
    { 
      label: (
        <div className="tab-label">
          <img src={colormobicon} alt="Color Icon" />
          <br />
          Color
        </div>
      ), 
      content: renderColorControls 
    },
    { 
      label: (
        <div className="tab-label">
          <img src={outlinefontmob} alt="Outline Icon" />
          <br />
          Outline
        </div>
      ), 
      content: renderOutlineControls 
    },
    { 
      label: (
        <div className="tab-label">
          <img src={spacingmobicon} alt="Letter Spacing Icon" />
          <br />
          Spacing
        </div>
      ), 
      content: renderLetterSpacingControls 
    },
    { 
      label: (
        <div className="tab-label">
          <img src={fontmobicon} alt="Font Icon" />
          <br />
          Font
        </div>
      ), 
      content: renderFontControls 
    },
    { 
      label: (
        <div className="tab-label">
          <img src={rotationmobtext} alt="Rotation Icon" />
          <br />
          Rotation
        </div>
      ), 
      content: renderRotationControls 
    }
  ];
  

  const handleTabChange = (index) => {
    setActiveTabIndex(index);
  };

  console.log("selectedFontName",selectedFontName);

  const OutlineToggle = () => {
    console.log("Done button clicked");
    setShowOutlineSettings((prev) => {
      console.log("showOutlineSettings before toggle:", prev);
      return !prev;
    });
  };
  
  return (
    <div style={{ padding: "0px" }}>
      {/* On mobile screen, show Add Text first and then tabs */}
      {isMobileScreen ? (
        <div>
          {/* Add Text Section (similar to desktop) */}
          {!isPropertiesView && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "3px",
                  backgroundColor: "#f6f6f6",
                }}
              >
                <h5
                  style={{
                    margin: "0 auto",
                    color: "#aaaa",
                    fontWeight: "400",
                    textAlign: "center",
                    flexGrow: 1,
                  }}
                >
                  Add Text
                </h5>
                <button
                  onClick={closePropertiesSidebar}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginLeft: "auto",
                  }}
                  aria-label="Close"
                >
      <img src={close} alt="Close" className="closeicon"/>
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "11px",
                  // height:'80vh',
                }}
              >
                <input
                  type="text"
                  id="text_value"
                  value={textValue}
                  onChange={handleTextChange}
                  placeholder="Enter text here"
                  className="placeholderStyle"
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    padding: "10px",
                    border: "1px solid #aaaa",
                    paddingLeft: "72px",
                    boxSizing: "border-box",
                  }}
                />
                <button  className="button-to-text"
                  style={{
                    marginTop: "10px",
                    padding: "10px 20px",
                    width: "60%",
                    backgroundColor: "rgb(28 190 217)",
                    boxShadow: "none",
                    color: "white",
                    border: "none",
                    borderRadius: "7px",
                  }}
                  onClick={handleAddTextToCanvas}
                >
                  Add To Text
                </button>
              </div>
            </>
          )}
  
          {/* Tabs Section */}
          {isPropertiesView && (
          <div className="mobile-tabs-container">
          {activeTabIndex !== null ? (
  <div className="tab-content ">

    
    <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "3px",
                  backgroundColor: "#f6f6f6",
                }}
              >
                <h5
                  style={{
                    margin: "0 auto",
                    color: "#211e1eaa",
                    fontWeight: "400",
                    textAlign: "center",
                    flexGrow: 1,
                  }}
                >
                  Edit Text
                  
                </h5>
                <button
                   onClick={closePropertiesSidebar}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginLeft: "auto",
                    color: "#aaaa",
                    fontWeight: "300",
                  }}
                  aria-label="Close"
                >
                                          <img src={close} alt="Close" className="closeicon"/>

                </button>
              </div>
              <div className="tab-mobile-content">
    {tabs[activeTabIndex].content()}
    </div>
  </div>
) : (
  <div className="tab-placeholder">
  </div>
)}
            <ul className="tab-list">
              {tabs.map((tab, index) => (
                <li
                  key={index}
                  className={activeTabIndex === index ? "active" : ""}
                  onClick={() => handleTabChange(index)}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
   

          </div>
        )}
        </div>
      ) : (
        // Existing layout for larger screens
        <>
          {!isPropertiesView && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "3px",
                  backgroundColor: "#f6f6f6",
                }}
              >
                <h5
                  style={{
                    margin: "0 auto",
                    color: "#211e1eaa",
                    fontWeight: "400",
                    textAlign: "center",
                    flexGrow: 1,
                  }}
                >
                  Edit Text
                  
                </h5>
                <button
                   onClick={closePropertiesSidebar}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginLeft: "auto",
                    color: "#aaaa",
                    fontWeight: "300",
                  }}
                  aria-label="Close"
                >
                                          <img src={close} alt="Close" className="closeicon"/>

                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column", 
                  alignItems: "center",
                  padding: "11px",
                }}
              >
                <input
                  type="text"
                  id="text_value"
                  value={textValue}
                  onChange={handleTextChange}
                  placeholder="Enter text here"
                  className="placeholderStyle"
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    padding: "10px",
                    border: "1px solid #aaaa",
                    paddingLeft: "90px",
                    boxSizing: "border-box",
                  }}
                />
                <button className="button-to-text"
                  style={{
                    marginTop: "10px",
                    padding: "10px 20px",
                    width: "60%",
                    backgroundColor: "rgb(28 190 217)",
                    boxShadow: "none",
                    color: "white",
                    border: "none",
                    borderRadius: "7px",
                  }}
                  onClick={handleAddTextToCanvas}
                >
                  Add To Text
                </button>
              </div>
            </>
          )}
  
          {isPropertiesView && !showColorPalette && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "3px",
                  backgroundColor: "#f6f6f6",
                }}
              >
                <h5
                  style={{
                    margin: "0 auto",
                    color: "#211e1eaa",
                    fontWeight: "400",
                    textAlign: "center",
                    flexGrow: 1,
                  }}
                >
                  Edit Text
                  
                </h5>
                <button
                   onClick={closePropertiesSidebar}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginLeft: "auto",
                    color: "#aaaa",
                    fontWeight: "300",
                  }}
                  aria-label="Close"
                >
                                          <img src={close} alt="Close" className="closeicon"/>

                </button>
              </div>
              <div className="detail-text-box">
                {isFontSelectorOpen ? (
                  <FontSelector
                    text={selectedObject.originalText}
                    onFontFamilyChange={handleFontChange}
                    onClose={toggleFontSelector}
                    handleCategoryChange={handleCategoryChange}
                    selectedCategory={selectedCategory}
                  />
                ) : isArcSelectorOpen ? (
                  <ArcTypeSelector
                    selectedArcType={warpType}
                    onSelectArcType={handleWarpTypeChange}
                    bend={bend}
                    handleBendChange={handleBendChange}
                    horizontalDistort={horizontalDistort}
                    handleHorizontalDistortChange={handleHorizontalDistortChange}
                    verticalDistort={verticalDistort}
                    handleVerticalDistortChange={handleVerticalDistortChange}
                    onClose={toggleArcSelector}
                  />
                ) : showOutlineSettings ? (
                  <div className="outline-settings">
  <div className="inner">
    <label>Choose Outline Thickness:</label>
  </div>
  <div className="inner">
    <br></br>
    <input
      className="range-bar"
      type="range"
      min="0"
      max="10"
      step="1"
      value={strokeWidth}
      onChange={handleStrokeWidthChange}
    />
  </div>
  <div className="inner">
    <label>Choose Outline Color:</label>
  </div>
  <div className="inner">
    <div className="color-boxes">
     
      {outlineColors.map((color) => (
        <div
          key={color}
          className={`color-box ${strokeColor === color ? "outline-selected" : ""}`}
          style={{
            backgroundColor: color,
            width: "25px",
            height: "25px",
            margin: "5px",
            cursor: "pointer",
            borderRadius: "5px",
          }}
          onClick={() => handleStrokeColorChange(color)}
        />
      ))}
    </div>

  
  </div>
  <div className="inner">  <button
        className="none-box donebutton"
       
        onClick={() => handleStrokeColorChange("none")}
      >
        None
      </button>


      <button onClick={OutlineToggle} className='donebutton' style={{float:'right'}}>Done</button>
      
      </div>
</div>

                ) : (
                  <div className="detail-text-box-body">
                    <input
                      type="text"
                      id="text_value"
                      value={textValue}
                      onChange={handleTextChange}
                      placeholder="Enter your text"
                      style={{ width: "97%", padding: "10px" }}
                    />
                    <div className="icon-row">
                      <button title="Center Object" onClick={handleCenterObject}        className={isCentered ? "selected" : ""}
>
                      <img src={objectcenter} alt="bringtofront" className="top-icon"/>
                      </button>
                      <button title="Bring to Front" onClick={handleBringToFront}  className={isSelected ? "selected" : ""}>
                      <img src={bringtofront} alt="bringtofront" className="top-icon"/>
                  
                      </button>
                      <button title="Flip Horizontal" onClick={handleFlipHorizontal}  className={isFlippedHorizontal ? "selected" : ""}>
                      <img src={mirrorhorizontal} alt="bringtofront" className="top-icon"/>

                
                      </button>
                      <button title="Flip Vertical" onClick={handleFlipVertical}        className={isFlippedVertical ? "selected" : ""}
>
                      <img src={mirrorvertical} alt="bringtofront" className="top-icon"/>

               
                      </button>
                      <button title="Lock/Unlock Object" onClick={handleToggleLock}>
  {isLocked ? (
    <img src={lock} alt="Locked" className="top-icon" />
  ) : (
    <img src={unlock} alt="Unlocked" className="top-icon" />
  )}
</button>

                    </div>

                    
                    <div className="inner">
                      <label className="labFont">Font</label>
                      <button
                        onClick={toggleFontSelector}
                        className="font-display-button font-name"
                        style={{ selectedFont }}
                      >
                        {selectedFontName}
                      </button>
                    </div>
                    <div className="inner">
  <label className="labFont">Arc</label>
  <button
    onClick={toggleArcSelector}
    className="font-display-button"
    style={{ warpType }}
  >
    {warpTypeDisplayNames[warpType] || "None"}
  </button>
</div>

                    <div className="inner">
                      <label className="labFont">Rotation</label>
                      <input className="range-bar"
                        type="range"
                        min="0"
                        max="360"
                        value={rotation}
                        id="rotation_control"
                        onChange={handleRotationChange}
                      />
                      <button
                        value="rotation_control" className="resetbtn"
                        onClick={() => handleRotationChange({ target: { value: 0 } })}
                      >
                        Reset
                      </button>
                    </div>
                    <div className="inner">
                      <label className="labFont">Text Size</label>
                      <input
                        type="input"
                        value={Math.round(textwidth * textheight)}
                        style={{ width: "32px" }}
                        disabled
                      />
                    </div>
                    <div className="inner">
                      <label className="labFont">Spacing</label>
                      <input className="range-bar"
                        type="range"
                        min="0"
                        max="1"
                        step=".1"
                        value={letterSpacing}
                        id="letter_spacing_control"
                        onChange={handleLetterSpacingChange}
                      />
                      <button
                        value="letter_spacing_control"  className="resetbtn"
                        onClick={() => handleLetterSpacingChange({ target: { value: 0 } })}
                      >
                        Reset
                      </button>
                    </div>
                    <div className="inner">
                      <label className="labFont">Outline</label>
                      {strokeColor === "none" ? (
                        <div onClick={toggleOutlineSettings} className="outline-main">
                          <span style={{ cursor: "pointer", color: "gray",fontSize:"16px",fontWeight:'300'}}>None</span>
                        </div>
                      ) : (
                        <div onClick={toggleOutlineSettings} className="outline-main">
                          <span
                            className="color-box"
                            style={{
                              backgroundColor: strokeColor,
                              width: "25px",
                              height: "25px",
                              margin: "5px",
                              cursor: "pointer",
                              borderRadius: "5px",
                            }}
                          ></span>
                        </div>
                      )}
                    </div>
                    <div className="inner">
                      <ObjectColorComponent
                        canvas={getCanvasByPartName(activeTab)}
                        selectedObject={selectedObject}
                        selectedObjectColors={selectedObjectColors}
                        setSelectedObjectColors={setSelectedObjectColors}
                        selectedColor={selectedColor}
                        setSelectedColor={setSelectedColor}
                        showColorPalette={showColorPalette}
                        setShowColorPalette={setShowColorPalette}
                        inkColors={inkColors}
                        setInkColors={setInkColors}
                        onColorChange={onColorChange}
                        handleFillChange={handleFillChange}
                        addToHistory={addToHistory}
                        calculatePrice={() => calculatePrice(getCanvasByPartName(activeTab))}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
  
          {isPropertiesView && showColorPalette && (
            <div>
              <div style={{ backgroundColor: "rgb(246 246 246)", padding: "4px" }}>
                <h5
                  style={{
                    textAlign: "center",
                    margin: "0 auto",
                    padding: "4px",
                    margin: "0px",
                    width: "80%",
                    display: "inline",
                  }}
                >
                  Edit Color
                </h5>
                <button
                   onClick={closePropertiesSidebar}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginLeft: "auto",
                    color: "#aaaa",
                    fontWeight: "300",
                    float: "right",
                    width: "10%",
                  }}
                  aria-label="Close"
                >
                                        <img src={close} alt="Close" className="closeicon"/>

                </button>
              </div>
              <div className="color-palettess">
              <div className="inners">
                <p style={{ paddingTop: "10px", paddingLeft: "0px" }}></p>
                </div>
                <div className="inner">

              <ObjectColorComponent
                canvas={getCanvasByPartName(activeTab)}
                selectedObject={selectedObject}
                selectedObjectColors={selectedObjectColors}
                setSelectedObjectColors={setSelectedObjectColors}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                showColorPalette={showColorPalette}
                setShowColorPalette={setShowColorPalette}
                inkColors={inkColors}
                setInkColors={setInkColors}
                onColorChange={onColorChange}
                handleFillChange={handleFillChange}
                addToHistory={addToHistory}
                calculatePrice={() => calculatePrice(getCanvasByPartName(activeTab))}
              />
              </div>
              {/* <button className="button-to-text"
                style={{
                  marginTop: "10px",
               
                  boxShadow: "none",
                  color: "white",
                  border: "none",
                  borderRadius: "7px",
                  float: "right",
                
                  fontSize: "15px",
                }}
              >
                Donesdsa
              </button> */}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
  
  
};

export default AddTextComponent;
