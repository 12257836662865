import { useState, useEffect } from "react";
import Replicate from "replicate";
import "./aisidebar.css";
import close from '../assets/close.png';
import aistick from '../assets/aistick.webp';

const AiSidebar = ({ setImageFile, setActiveSidebar, savedImages, setSavedImages,closePropertiesSidebar }) => {
  const [prompt, setPrompt] = useState("");
  const [output, setOutput] = useState([]);
  const [loading, setLoading] = useState(false);

  // Load saved images when the component mounts
  useEffect(() => {
    if (savedImages.length > 0) {
      setOutput(savedImages);
    }
  }, [savedImages]);

  const handleGenerate = async () => {
    setLoading(true);
    setOutput([]); // Clear previous images before generating a new one

    try {
        // Step 1: Send prompt to the backend API to create an image generation request
        const createResponse = await fetch("https://backend.inkox.com/api/generate-image", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ text: prompt }), // Sending the text input
        });

        if (!createResponse.ok) {
            throw new Error(`Failed to create image request: ${createResponse.statusText}`);
        }

        const createData = await createResponse.json();
        console.log("Create Response:", createData);

        // Extract the image generation ID from the response
        const predictionId = createData.id;

        // Step 2: Poll the second API until the image is ready
        let imageUrl = null;
        let status = "starting";

        while (status !== "succeeded" && status !== "failed") {
            const predictionResponse = await fetch(`https://backend.inkox.com/api/prediction/${predictionId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!predictionResponse.ok) {
                throw new Error(`Failed to fetch prediction: ${predictionResponse.statusText}`);
            }

            const predictionData = await predictionResponse.json();
            console.log("Prediction Response:", predictionData);

            status = predictionData.status;

            if (status === "succeeded") {
                imageUrl = predictionData.output;
                break;
            } else if (status === "failed") {
                throw new Error("Image generation failed.");
            }

            // Wait for 2 seconds before polling again
            await new Promise((resolve) => setTimeout(resolve, 2000));
        }

        if (imageUrl) {
            const newImages = [imageUrl, ...output]; // Add new image to the output list
            setOutput(newImages);
            setSavedImages(newImages);
        }
    } catch (error) {
        console.error("Error generating image:", error);
    }

    setLoading(false);
};

  // Function to convert an image URL to a File object
  const handleImageClick = async (imageUrl, index) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], `out-${index}.webp`, {
        type: "image/webp",
        lastModified: new Date().getTime(),
      });
      setImageFile(file);
      setActiveSidebar("uploadImage");
    } catch (error) {
      console.error("Error converting image to file:", error);
    }
  };

  return (
    <div className="p-4 bg-gray-200 w-64 h-full shadow-lg">
     <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3px",
          backgroundColor: "#f6f6f6",
        }}
      >
        <h5
          style={{
            margin: "0 auto",
            color: "#211e1eaa",
            fontWeight: "400",
            textAlign: "center",
            flexGrow: 1,
          }}
        >
          Generate Image With AI
        </h5>
        
        <button
          onClick={closePropertiesSidebar}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            fontSize: "18px",
            fontWeight: "bold",
            marginLeft: "auto",
            color: "#aaaa",
            fontWeight: "300",
            float: "right",
          
          }}
          aria-label="Close"
        >
          <img src={close} alt="Close" className="closeicon"/>
        </button>
      </div>
      <textarea
  className="w-full p-2 mt-4 border rounded text-aiprompt"
  placeholder="Enter prompt"
  value={prompt}
  onChange={(e) => setPrompt(e.target.value)}
  onInput={(e) => {
    e.target.style.height = "auto"; // Reset height to auto
    e.target.style.height = e.target.scrollHeight + "px"; // Set height based on content
  }}
  style={{ overflow: "hidden", resize: "none" }} // Prevent manual resizing
></textarea>


   
<button
  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded button-generate-aiimage"
  onClick={handleGenerate}
  disabled={loading || prompt.trim() === ""}
>
  <img src={aistick} alt="AI Icon" />
  {loading ? "Generating..." : "Generate"}
</button>

      <div className="mt-4 ai-images-main">
        {output.map((item, index) => (
          <img
            key={index}
            src={item}
            alt={`output ${index}`}
            className="mt-2 w-full displayimageai cursor-pointer"
            onClick={() => handleImageClick(item, index)}
          />
        ))}
      </div>
    </div>
  );
};

export default AiSidebar;
