import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { fabric } from 'fabric';
import './ProductColor.css';
import close from '../assets/close.png';

const UploadImageComponent = ({ canvas, setSelectedImageColors, setIsPng, calculatePrice, closePropertiesSidebar, imageFile ,setImageFile }) => {
  const [loading, setLoading] = useState(false);  // Loading state for showing popup
  const [uploading, setUploading] = useState(false);  // State for the uploading process
  const btnRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const lastImageFile = useRef(null);
  console.log("imageFile",imageFile);

  // Check if image has alpha channel
  function hasAlpha(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();
   
      img.src = URL.createObjectURL(file);
      
      img.onerror = (error) => {
        reject(error);
      };
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
  
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
  console.log("img",img);
        let isSolidBackground = true;
  
        for (let i = 3; i < imageData.length; i += 4) {
          if (imageData[i] < 255) {
            isSolidBackground = false;
            break;
          }
        }
  
        resolve(isSolidBackground);
      };
    });
  }

  useEffect(() => {
    if (!lastImageFile.current && !imageFile) return; // Prevent running on first render
  
    if (imageFile && lastImageFile.current !== imageFile) {
      lastImageFile.current = imageFile; // Update stored value
      sendImageToApi("https://colors.inkox.com/api/process-image", imageFile);
    }
  }, [imageFile]);
  
  // Function to send image to API
  const sendImageToApi = async (apiUrl, imageFile) => {
    try {
      setUploading(true);  // Show popup during upload

      const formData = new FormData();
      formData.append("file", imageFile);
      console.log("FormData content:", formData.get("file"));


      const isSolidBackground = await hasAlpha(imageFile);
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      
      if (response.status === 200) {
        const base64Image = response.data.quantized_image;
        const paletteColors = response.data.palette_colors;
        const sessionId = response.data.session_id;
        const dominantColor = response.data.dominant_color;
        setSelectedImageColors(paletteColors);

        const imgObj = new Image();
        imgObj.src = `data:image/jpeg;base64,${base64Image}`;
        imgObj.crossOrigin = "Anonymous";
        imgObj.onload = () => {
          const designArea = canvas.getObjects().find(obj => obj.designArea);
          if (designArea) {
            const leftPercent = 0;
            const topPercent = 0;
            const widthPercent = 40;

            const left = designArea.left + (designArea.width * leftPercent) / 100;
            const top = designArea.top + (designArea.height * topPercent) / 100;
            const targetWidth = designArea.width * (widthPercent / 100);
            const scaleFactor = targetWidth / imgObj.width;
            const img = new fabric.Image(imgObj, {
              left,
              top,
              paletteColors,
              scaleX: scaleFactor,
              scaleY: scaleFactor,
              selectable: true,
              designElement: true,
              paletteColors,
              imageFile: isSolidBackground,
              imageType: imageFile.type,
              bgRemove: false,
              dominant_color: dominantColor,
              session_id: sessionId,
              leftPercent,
              topPercent,
              widthPercent,
              heightPercent: (imgObj.height * scaleFactor) / designArea.height * 100
            });

            img.setCoords();
            canvas.add(img);
            canvas.setActiveObject(img);
            canvas.renderAll();
            calculatePrice(canvas); // Calculate price after changing the color
          }
        };
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }  finally {
      setUploading(false);  // Hide popup after upload completes
      lastImageFile.current = null; // Reset last image reference
      setTimeout(() => {
        setImageFile(null); // Reset imageFile after processing
        console.log("ImageFile Is Now Set As Null");
      }, 100); // Ensures UI updates
    }
  };


  
  // Handle image change
  const changeImage = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
        // Log file details for debugging
        console.log("File selected:", file);
        console.log("File type:", file.type);

        // Ensure the API supports PDFs
        if (file.type === "application/pdf") {
            console.log("PDF detected, sending to API...");
        }

        // Send file to the API
        await sendImageToApi("https://colors.inkox.com/api/process-image", file);
        
    }
    setLoading(false);
};


  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      changeImage({ target: { files: [file] } });
    }
  };

  const handleClick = () => {
    btnRef.current.click();
  };

  return (
    <>
      {/* Full-screen Popup for uploading */}
      {uploading && (
        <div className="uploading-popup-overlay">
          <div className="uploading-popup-contents">
            <div className="spinner"></div>
            <p className='upload-spinner-text'>Please wait! We’re mixing up the colors just for you!</p>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3px",
          backgroundColor: "#f6f6f6",
        }}
      >
        <h5
          style={{
            margin: "0 auto",
            color: "#211e1eaa",
            fontWeight: "400",
            textAlign: "center",
            flexGrow: 1,
          }}
        >
          Choose file 
        </h5>
        
        <button
          onClick={closePropertiesSidebar}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            fontSize: "18px",
            fontWeight: "bold",
            marginLeft: "auto",
            color: "#aaaa",
            fontWeight: "300",
            float: "right",
          
          }}
          aria-label="Close"
        >
          <img src={close} alt="Close" className="closeicon"/>
        </button>
      </div>

      <div className='highart'>
        <p className='highfont'>
          High resolution artwork will look the best.
        </p>
      </div>

      <div 
        className={`upload-container ${isDragging ? 'dragging' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <div className="upload-box">
          <div className="upload-icon">
            <svg id="_11-upload" data-name="11-upload" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.98 38.98"><path className="cls-1" d="M19.38 24.81h.22l-.11-.1Z"></path><path className="cls-1" d="M27.91 15.91h-.35v-.53a6.39 6.39 0 0 0-12.22-2.61 3.81 3.81 0 0 0-5.3 2.78 4.71 4.71 0 0 0 1.26 9.24h3.75l.06-.08L18 21.94a2.08 2.08 0 0 1 2.89 0l2.9 2.79.06.08h4a4.45 4.45 0 0 0 .06-8.9Z"></path><path className="cls-1" d="M22.79 25.81 19.9 23a.58.58 0 0 0-.81 0l-2.9 2.79a.58.58 0 0 0 .41 1h1.47V30h2.86v-3.18h1.47a.58.58 0 0 0 .39-1.01Z"></path></svg>
          </div>
          <p className="upload-text">Drag & Drop or <br/><span className="browse-text">Browse</span> Your Computer</p>
          <p className="file-types">JPG, PNG, EMF, JFIF, BMP and PDF (Max 5 MB)</p>
          <input 
            type="file" 
            ref={btnRef} 
            className="file-input" 
            onChange={changeImage}
            style={{ display: 'none' }} 
          />
        </div>
      </div>
    </>
  );
};

export default UploadImageComponent;
